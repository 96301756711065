import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import StyleConstants from '../../shared/styleConstants/styles';
import { ICON_CONFIG } from '../../shared/constants/fieldTypes';

import '../../shared/styleConstants/loader.css';

class Loader extends PureComponent {
  state = {
    screenHeight: 0,
  };

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }
  render() {
    const { height } = this.props;
    return (
      <div className="loader" style={{ height: height ? height : this.state.screenHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'  }}>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img alt='loading' src={ICON_CONFIG.LOADING} width={250} height={250}/>
        </div>
        <div className={!height && "pt-3"} />
      </div>
    )
  }
}

Loader.propTypes = {
  height: PropTypes.number,
}

export default Loader;
