import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { logout } from './actions';
import { UserAuthenticationModes, ResetPassword, ForgotPassword, StudentLogin, SwitchMembership } from './components';
import { getInsituteDomain, getParentDomain } from './helpers/Utils';
import { MEMBERSHIP_TYPES } from './actions'
import { Loader, Header } from './components/common';
import constants from './shared/constants';
import Cookies from 'universal-cookie';
import NotifyServerMaintenance from './notifyServerMaintenance';

const cookies = new Cookies();
const Constants = constants();
class RouteNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: '',
    }
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }

  redirectToLandingPage() {
    const user = cookies.get('user');
    if (user) {
      if (user.type === MEMBERSHIP_TYPES.ADMIN || user.type === MEMBERSHIP_TYPES.TEACHER || user.type === MEMBERSHIP_TYPES.COUNSELLOR) {
        window.location.replace(getInsituteDomain(user.is_higher_education, user.type === MEMBERSHIP_TYPES.TEACHER));
      } else if (user.type === MEMBERSHIP_TYPES.GUARDIAN) {
        window.location.replace(getParentDomain());
      } else if (user.type === MEMBERSHIP_TYPES.STUDENT && user.is_higher_education) {
        window.location.replace(Constants.landingUrl + '/virtual_meeting');
      } else {
        window.location.replace(Constants.landingUrl);
      }
    }
    return null;
  }

  handleLogout() {
    this.props.logout();
    window.location.replace(Constants.landingUrl);
    return null;
  };

  redirectToOAuth() {
    window.location.href = `/oauth/token?token=${cookies.get('token')}`;
    return null;
  }

  render() {
    const { screenHeight } = this.state;
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('utm_source') != null) {
      const utmParams = window.location.search.spice(1).split('&');
      const utmDetails = {};
      utmParams.forEach((param) => {
        const params = param.split('=');
        utmDetails[params[0]] = decodeURIComponent(params[1] || '');
      });
      cookies.set('utmDetails', JSON.stringify(utmDetails), { domain: Constants.cookie.domainRegex, secure: Constants.cookie.secure });
    }
    return (
      <Router>
        <div className="App" style={{ minHeight: screenHeight, height: '100% !important', backgroundColor: Constants.primaryBackgroundColor }}>
          <Header />
          {this.props.loaderState.show && <Loader />}
          {!this.props.loaderState.show &&
            <div className="App" style={{ paddingTop: 30, width: '100%' }}>
              <Switch>
                <Route exact path="/" render={this.redirectToLandingPage} />
                <Route path='/oauth/redirect' render={this.redirectToOAuth} />
                <Route path="/sign_in" component={UserAuthenticationModes} />
                <Route path='/sign_up' component={UserAuthenticationModes} />
                <Route path='/switch_membership' component={SwitchMembership} />
                <Route path='/reset_password' component={ResetPassword} />
                <Route path='/forgot_password' component={ForgotPassword} />
                <Route path='/student_login' component={StudentLogin} />
                <Route path='/logout' render={this.handleLogout} />
                <Route path='/notify_server_maintenance' component={NotifyServerMaintenance} />
              </Switch>
            </div>
          }
        </div>
      </Router>
    );
  }
}

RouteNavigation.propTypes = {
  loaderState: PropTypes.object,
  userProfile: PropTypes.object,
  logout: PropTypes.func.isRequired,
}

RouteNavigation.defaultProps = {
  loaderState: {},
  userProfile: {},
}

const mapStateToProps = ({ appState }) => ({
  loaderState: appState.loaderConfig,
});

export default connect(mapStateToProps, { logout })(RouteNavigation);
