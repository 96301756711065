import React, { PureComponent } from 'react';

class ActivityIndicator extends PureComponent {
    render() {
        return(
            <div>
                 <i class="fa fa-spinner fa-spin" />
            </div>
        )
    }
}
export default ActivityIndicator;
