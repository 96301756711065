import instituteIcon from '../images/institute_icon.svg';
import studentIcon from '../images/student_icon.svg';
import parentIcon from '../images/parent_icon.svg';
import instituteIconSelected from '../images/institute_icon_selected.svg';
import studentIconSelected from '../images/student_icon_selected.svg';
import parentIconSelected from '../images/parent_icon_selected.svg';
import checkIcon from '../images/check.svg';
import trialCheckIcon from '../images/check_trial.svg';
import closeIcon from '../images/close.svg';
import showPassword from '../images/show_password.png'
import hidePassword from '../images/hide_password.png';
import loading from '../images/load.gif';
import logout from '../images/logout.png'

export const TOAST_MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
}

export const ERROR_TYPE = {
  NO_ERROR: 0,
  EMAIL: 1,
  PASSWORD: 2,
  MOBILE: 3,
};

export const AUTH_MODE = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
}

export const USER_TYPE = {
  INSTITUTE: 'INSTITUTE',
  STUDENT: 'STUDENT',
  PARENT: 'PARENT',
}

export const AUTH_CONFIG = {
  SIGN_IN: ["INSTITUTE", "STUDENT", "PARENT"],
  SIGN_UP: ["INSTITUTE", "STUDENT"],
}

export const TITLE_CONFIG = {
  SIGN_IN: ["Institute Sign In", "Student Sign In", "Parent Sign In"],
  SIGN_UP: ["Institute Sign In", "Student Sign In"],
}

export const RESET_PASSWORD = {
  RESET_PASSWORD_TOKEN: 'reset_password_token',
  RESET_PASSWORD_ERROR: 'Invalid reset password link'
}

export const ICON_CONFIG = {
  INSTITUTE: instituteIcon,
  STUDENT: studentIcon,
  PARENT: parentIcon,
  INSTITUTE_SELECTED: instituteIconSelected,
  STUDENT_SELECTED: studentIconSelected,
  PARENT_SELECTED: parentIconSelected,
  CHECK_ICON: checkIcon,
  TRIAL_CHECK_ICON: trialCheckIcon,
  CLOSE_ICON: closeIcon,
  SHOW_PASSWORD: showPassword,
  HIDE_PASSWORD: hidePassword,
  LOADING: loading,
  LOGOUT: logout,
}

export const PAGE_TYPES = {
  PHONE: 'phone',
  EMAIL_OR_PHONE: 'email_or_phone',
  OTP: 'otp',
  REGISTER: 'register',
  PASSWORD: 'password',
}

export const AUTH_LIMIT_CONSTANTS = {
  MOBILE_NUMBER_LENGTH: 10,
  OTP_LENGTH: 6,
  PASSWORD_LENGTH: 8,
  OTP_EXPIRY_DURATION: 30
}

export const ERROR_MESSAGES = {
  NO_OTP: 'Please enter 6 digit OTP',
  INVALID_OTP: 'Incorrect OTP. Please try again',
  ALREADY_REGISTERED_PHONE: 'This phone number is already registered with us. Try logging in',
  INSTITUTE_NAME_MIN_LENGTH: 'Please enter your institute’s name (min 3 characters)',
  INVALID_EMAIL: 'Please enter a valid email address',
  INVALID_MOBILE_NUMBER: 'Please enter a valid mobile number',
  PASSWORD_MIN_LENGTH: 'Your password needs to be at least 8 characters',
  INCORRECT_PASSWORD: 'Incorrect password. Please try again',
  UNREGISTERED_EMAIL: 'Please enter your registered email address',
  UNREGISTERED_MOBILE_NUMBER: 'Please enter your registered phone number',
  UNREGISTERED_ID: 'Mobile number or student ID does not exist',
  INVALID_PASSWORD_RESET_TOKEN: 'Invalid reset password token',
  PASSWORD_MISMATCH: 'Your confirmation password do not match',
  NOT_A_STUDENT: 'This number is not a valid Student Number',
  NO_USER_TYPE: 'Select any user',
  BLOCK_STUDENT_LOGIN: 'Please use our App from playstore to use our service',
  ACCOUNT_INACTIVE: 'Selected Account is suspended, please contact us !',
  ACTIVE_ACCOUNTS: 'Select active account',
}

export const PAGE_TYPE_DATA = {
  phone: {
    topic: "Sign In",
    info: "Please enter your 10 digit mobile number to proceed",
    placeholder: "Mobile Number/Student ID",
    buttonText: "Continue",
    error: "Phone no will be 10 character",
  },
  otp: {
    topic: "Enter Your One Time Passcode",
    buttonText: "Verify"
  },
  register: {
    topic: "Register",
    info: "Please enter your personal details to continue.",
    placeholder: "Name",
    buttonText: "Save",
    error: "Invalid Email!",
  },
  password: {
    topic: "Password",
    info: "Please enter your password.",
    placeholder: "Password",
    buttonText: "Verify",
    error: "Password will be more than 7 character",
    confirmationPassword: "Password is not matched",
  }
}
