import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Text from './common/Text';
import { updateTimerExpirey } from '../actions/index';
import StyleConstants from '../shared/styleConstants/styles';
import { addLeadingZeros, calculateCountdown } from '../helpers/Utils';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: 0,
      minutes: 0,
      sec: 0,
    }
  }

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop(true);
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  stop(hasTimerExpired=false) {
    if (hasTimerExpired) {
      this.props.updateTimerExpirey(hasTimerExpired);
    }
    clearInterval(this.interval);
  }

  render() {
    const { hours, minutes, sec } = this.state;
    const color = minutes < 5 && hours === 0 ? minutes === 0 && hours === 0 && sec === 0 ? StyleConstants.color.border : StyleConstants.color.error : StyleConstants.color.testUnattempted;
    return (
      <span className="Timer">
        {this.props.type === "test" &&
          <Text
            color={color}
            fontWeight={StyleConstants.textWeight.bold}
            fontSize={StyleConstants.textSize.headerSmall}
            text={`${addLeadingZeros(hours)} : ${addLeadingZeros(minutes)} : ${addLeadingZeros(sec)}`}
          />
        }
        {this.props.type !== "test" &&
          <Text
            fontWeight={StyleConstants.textWeight.semiBold}
            text={ hours > 0 ? `${hours}h ${addLeadingZeros(minutes)}m` : `${minutes} : ${addLeadingZeros(sec)}`}
          />
        }
      </span>
    );
  }
}

Countdown.propTypes = {
  updateTimerExpirey: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Countdown.defaultProps = {
  date: new Date(),
  type: ''
};

export default connect(null, { updateTimerExpirey })(Countdown);
