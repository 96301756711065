import React, { PureComponent } from 'react';
import { isMobileDevice } from './helpers/Utils';
import SERVER_UNDER_MAINTENANCE from './shared/images/something-went-wrong.svg';
import SERVER_UNDER_MAINTENANCE_MOBILE from './shared/images/something-went-wrong-mobile.png';

const IS_MOBILE = isMobileDevice();

class NotifyServerMaintenance extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            screenHeight: '',
        };
    }

    componentDidMount() {
        this.setState({ screenHeight: global.innerHeight });
        window.scrollTo(0, 0);
    }

    render() {
      const { screenHeight } = this.state;

      if (IS_MOBILE) {
        return (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 20, marginTop: 50 }}>
              <div style={{ fontSize: 20 }}>
                Server under maintenance
              </div>
              <div style={{ marginTop: 20, fontSize: 16, color: 'gray' }}>
                please try after sometime
              </div>
            </div>
            <div style={{ height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0, bottom: 10 }}>
              <img style={{ width: '100%', height: '100%' }} alt="server_under_maintenance_mobile" src={SERVER_UNDER_MAINTENANCE_MOBILE} />
            </div>
          </div>
        );
      }
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'fixed', top: 135, left: '5%', zIndex: 1000 }}>
            <div style={{ fontSize: 30 }}>
              Server under maintenance
            </div>
            <div style={{ marginTop: 20, fontSize: 20, color: 'gray' }}>
              please try after sometime
            </div>
          </div>
          <div style={{ height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0, bottom: 10 }}>
            <img style={{ height: screenHeight - 70 }} alt="server_under_maintenance" src={SERVER_UNDER_MAINTENANCE} />
          </div>
        </div>
      );
    }
}

export default NotifyServerMaintenance;