import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/styleConstants/styles';

class Text extends PureComponent {

  render() {
    const { fontSize, color, fontWeight, text, vertical } = this.props;
    return (
      <p style={{ color, fontSize, fontWeight, padding: 2, display: 'inline', writingMode: vertical && "tb-rl", margin: 0 }}> {text} </p>
    )
  }
}

Text.propTypes = {
  vertical: PropTypes.bool,
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string
};

Text.defaultProps = {
  fontSize: StyleConstants.textSize.text,
  color: StyleConstants.textColor.secondary,
  fontWeight: '0',
  vertical: false
};

export default Text;
