import Cookies from 'universal-cookie';
import constants from '../shared/constants';
import { getUser } from '../helpers/Api';
import { isMobileDevice } from './Utils';

const IS_MOBILE = isMobileDevice();
const ua = require('universal-analytics');
const Constants = constants();
const cookies = new Cookies();
const user = getUser();
const visitor = ua(constants().google_analytics_key, user.id, {strictCidFormat: false}); 

export const googleAnalyticsPage = (path, hostname, title) => {
  visitor.pageview(path, hostname, title).send()
};

const googleAnalyticsEvent = (eventType, screenName, eventAction, properties) => {
 const params = {
    ec: eventType,
    ea: eventAction,
    el: JSON.stringify(properties),
    dp: screenName
  }
  visitor.event(params).send();
};

export const initAnalytics = () => {
  window.amplitude.getInstance().init(Constants.amplitudeApiKey);
};

const amplitudeEvent = (eventType, eventProperties) => {
  window.amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const sendEvent = (eventType, screenName='', eventAction='', property = {}) => {
  let user = cookies.get('user');
  let utmDetails = cookies.get('utmDetails');
  let properties = {}
  const deviceType = IS_MOBILE ? 'Mobile' : 'Desktop'
  if(utmDetails !== undefined) {
    properties = { ...property, ...user, referrer: utmDetails, deviceType};
  } else {
    properties = { ...property, ...user , deviceType}
  }
  if (typeof amplitude !== 'undefined') {
    if(eventAction === 'View') {
      amplitudeEvent(`Authentication - ${eventType} ${eventAction}`, properties);
    } else {
      amplitudeEvent(`Authentication - ${eventType} ${screenName} ${eventAction}`, properties);
    }
  }
  delete properties['token'];
  googleAnalyticsEvent(eventType, screenName, eventAction, properties);
};