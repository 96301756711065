import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { sendEvent } from '../helpers/Analytics';
import Text from './common/Text';
import { ICON_CONFIG, USER_TYPE, ERROR_MESSAGES } from '../shared/constants/fieldTypes';
import StyleConstants from '../shared/styleConstants/styles';
import { ActivityIndicator, Loader } from './common';
import constants from '../shared/constants';
import { isMobileDevice } from '../helpers/Utils';
import { getUserTypes, updateCurrentMembership, updateAuth, logout } from '../actions';

const Constants = constants();
const IS_MOBILE = isMobileDevice();
const styles = {
  boxStyle: {
    backgroundColor: Constants.primaryButtonColor,
    justifyContent: 'center',
    paddingTop: 11,
    paddingBottom: 11,
    cursor: 'pointer',
    borderRadius: 2,
    boxShadow: '0px 3px 6px 0px rgba(108,92,231,0.5)',
    marginBottom: 5,
    margin: 'auto',
    textAlign: 'center',
    marginTop: '20px',
    maxWidth: '300px',
  },
  textStyle: {
    color: '#FFFFFF',
    fontSize: 18,
    marginTop: 10,
    textAlign: 'center',
    fontWeight: 600,
    userSelect: 'none',
  },
  mainHeaderStyle: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: IS_MOBILE ? -10 : 0,
  },
};

class SwitchMembership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      tooltipContent: null,
      tooltipShowType: '',
    };
    this.tooltipShow = this.tooltipShow.bind(this);
  }
  componentDidMount() {
    sendEvent('SwitchMembership', 'SignIn', 'View')
    this.props.getUserTypes();
  }

  tooltipShow(tooltipContent, tooltipShowType, tooltip, additionalStateProps = {}) {
    this.setState({ tooltipContent, tooltipShowType, ...additionalStateProps, isLoader: false }, () => {
      ReactTooltip.show(tooltip);
    });
  }

  render() {
    const { isLoader } = this.state;
    let { userTypes, multiUserAuthId, loader, blockStudentlogin, userType } = this.props;
    let disableMembershipChange = false;
    const isStudentAndBlockStudentLogin = blockStudentlogin && userType === USER_TYPE.STUDENT

    if (userTypes.length > 0) {
      disableMembershipChange = userTypes[0].current_membership_id === multiUserAuthId;
    }
    if (loader) {
      return (
        <Loader />
      )
    }
    else {
      return (
        <div style={{ width: 'fit-content', margin: 'auto', maxWidth: 800 }}>
          <div style={styles.mainHeaderStyle}> Switch membership to</div>
          <div className="row justify-content-center" style={{ alignItems: 'center', display: 'flex', width: IS_MOBILE ? 250 : 700, margin: 'auto' }} ref={ref => this.multipleUserToolTip = ref} data-tip='tooltip' data-for="multiple-user-tooltip" data-tip-disable={this.state.tooltipShowType !== 'multipleUser'}>
            <ReactTooltip id="multiple-user-tooltip" place="top" type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
            {
              userTypes.map((userType) => {
                return (
                  <div
                    className="col"
                    style={{
                      maxWidth: 300,
                      backgroundColor: userType.id === multiUserAuthId ? '#F8F7FF' : '#FFFFFF', textAlign: 'center', position: 'relative', alignItems: 'center', boxShadow: '1px 2px 6px #8B9DAF26',
                      cursor: 'pointer', margin: 10, padding: IS_MOBILE ? '0px 20px' : '0px 40px', border: userType.id === multiUserAuthId ? `1px solid ${Constants.primaryButtonColor}` : "", justifyContent: 'center',
                      borderRadius: 4, display: "inline-grid", height: IS_MOBILE ? 150 : 230
                    }}
                    key={userType.id}
                    onClick={() => {
                      ReactTooltip.hide(this.multipleUserToolTip);
                      this.props.updateAuth({ multiUserAuthId: userType.id, userType: userType.user_type })
                      if (isStudentAndBlockStudentLogin) {
                        this.tooltipShow(ERROR_MESSAGES.BLOCK_STUDENT_LOGIN, 'multipleUser', this.multipleUserToolTip);
                      }
                    }}
                  >
                    {userType.id === multiUserAuthId && <img src={ICON_CONFIG.CHECK_ICON} style={{ position: 'absolute', bottom: -8, right: -8, height: 25, width: 25 }} alt="selected" />}
                    <img alt={userType.user_type} style={{ width: IS_MOBILE ? '90px' : '130px', alignSelf: "flex-end", height: IS_MOBILE ? 90 : 130, display: 'flex', justifySelf: 'center' }} src={ICON_CONFIG[`${userType.user_type}${"_SELECTED"}`]} />
                    <div style={{ textTransform: 'capitalize', textAlign: 'center', width: "100%" }}>
                      <Text
                        fontWeight={StyleConstants.textWeight.bold}
                        color='#454545'
                        fontSize={22}
                        text={userType.user_type.toLowerCase()}
                      />
                    </div>
                    <div style={{ marginTop: -15 }}>
                      <Text
                        fontWeight={StyleConstants.textWeight.bold}
                        fontSize={14}
                        text={userType.name.toLowerCase()}
                      />
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div id="multiple accounts"
            style={{ ...styles.boxStyle, opacity: (disableMembershipChange || isStudentAndBlockStudentLogin) ? 0.5 : 1, cursor: disableMembershipChange ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              if (isStudentAndBlockStudentLogin) {
                this.tooltipShow(ERROR_MESSAGES.BLOCK_STUDENT_LOGIN, 'multipleUser', this.multipleUserToolTip);
              } else {
                if (!disableMembershipChange) {
                  this.setState({ isLoader: true })
                  this.props.updateCurrentMembership({ membership_id: multiUserAuthId })
                }
              }
            }}
          >
            <span style={styles.textStyle}>{(isLoader) ? <ActivityIndicator /> : 'Continue'}</span>
          </div>
          <div
            style={{ textAlign: 'center', fontSize: 16, cursor: 'pointer', width: 'fit-content', margin: 'auto', paddingTop: 15 }}
            onClick={() => {
              this.setState({ isLoader: true })
              this.props.logout();
              window.location.replace(Constants.landingUrl);
            }}
          >
            <span>Log out</span>
            <img alt={'Log out'} style={{ width: 20, marginLeft: 5, opacity: 0.5 }} src={ICON_CONFIG['LOGOUT']} />
          </div>
        </div>
      );
    }
  };
}

SwitchMembership.propTypes = {
  userTypes: PropTypes.array.isRequired,
  getUserTypes: PropTypes.func.isRequired,
  updateCurrentMembership: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  loader: PropTypes.bool.isRequired,
  multiUserAuthId: PropTypes.number.isRequired,
  updateAuth: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  blockStudentlogin: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  loader: auth.loader,
  userTypes: auth.userTypes,
  userType: auth.userType,
  blockStudentlogin: auth.blockStudentlogin,
  multiUserAuthId: auth.multiUserAuthId,
});

export default connect(mapStateToProps, { getUserTypes, updateCurrentMembership, updateAuth, logout })(SwitchMembership);
