import { createStore, applyMiddleware, compose } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import reducers from '../../reducers';

export default function configureStore(preloadedState) {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging' ? compose(...storeEnhancers) : composeWithDevTools(...storeEnhancers);

  const store = createStore(
    reducers,
    preloadedState,
    composedEnhancer,
  );

  return store;
}
