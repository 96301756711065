import React, { Component } from 'react';
import ReactModal from 'react-modal'

class Modal extends Component {
  render () {
    const { children, showModal, handleCloseModal, header, height, width , minwidth } = this.props;
    return (
      <div>
        <ReactModal
           ariaHideApp={false}
           isOpen={showModal}
           contentLabel="onRequestClose Example"
           onRequestClose={handleCloseModal}
           style = {{
             overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: '#00000080',
                overflowY: 'scroll',
                zIndex: 5,
            },
             content: {
               position: 'absolute',
               top: '50%',
               left: '50%',
               height: height || 'auto',
               width: width || 'auto',
               right: 'auto',
               bottom: 'auto',
               transform: 'translate(-50%, -50%)',
               overflowY: 'scroll',
               WebkitOverflowScrolling: 'scroll',
               minWidth: minwidth || '25%',
               padding: 10,
            }
           }}
        >
          {header &&
            <div className="d-flex justify-content-between" style={{ borderBottom: '1px solid #efefef', padding: 20, alignItems: 'center' }}>
              <b style={{ fontSize: 18, paddingLeft: 10 }}> {header} </b>
              <i className="fa fa-times cursor ml-5" aria-hidden="true" style={{ paddingRight: 10, fontSize: 20, color: '#ccc' }} onClick={() => handleCloseModal()}></i>
            </div>
          }
          {children}
        </ReactModal>
      </div>
    );
  }
}
export default Modal;
