import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import RouteNavigation from './routeNavigation';
import './App.css';
import constants from './shared/constants';
import { initAnalytics } from './helpers/Analytics';

const cookies = new Cookies();
const history = createBrowserHistory({
  forceRefresh: true
});
const Constants = constants();

class App extends Component {
  render() {
    const userProfile = cookies.get('user');
    const publicPaths = ['/sign_in', '/sign_up', '/forgot_password', '/reset_password', '/logout', '/student_login', '/notify_server_maintenance'];
    initAnalytics();
    if(userProfile) {
      if(window.location.pathname !== '/switch_membership'){
        window.location.replace(Constants.landingUrl);
      }
    }
    if (!userProfile && ['/oauth/redirect'].includes(window.location.pathname)) {
      history.push(`/sign_in?continue=${encodeURIComponent(window.location.href)}`);
    }
    if (!userProfile && !publicPaths.includes(window.location.pathname)) {
      history.push('/sign_in');
    }
    if (Constants.cookie.secure) {
      return (
        <HttpsRedirect>
          <RouteNavigation userProfile={userProfile} />
        </HttpsRedirect>
      )
    }
    return (
      <RouteNavigation userProfile={userProfile} />
    );
  }
}

export default App;
