import constants from '../shared/constants';
import { USER_TYPE } from '../shared/constants/fieldTypes';

export const calculateTime = (timeInSeconds) => {
    const timeLeft = { hours: 0, minutes: 0, sec: 0 };

    if (timeInSeconds >= 3600) {
      timeLeft.hours = Math.floor(timeInSeconds / 3600);
      timeInSeconds -= timeLeft.hours * 3600;
    }

    if (timeInSeconds >= 60) {
      timeLeft.minutes = Math.floor(timeInSeconds / 60);
      timeInSeconds -= timeLeft.minutes * 60;
    }

    timeLeft.sec = timeInSeconds;
    return timeLeft;
  }

export const convertConvention = (userType) => {
  const userTypes = { "Admin": USER_TYPE.INSTITUTE, "Guardian": USER_TYPE.PARENT, "Student": USER_TYPE.STUDENT, "Counsellor": USER_TYPE.INSTITUTE, "Teacher": USER_TYPE.INSTITUTE }
  return userTypes[userType]
} 

export const calculateCountdown = (endDate, countDownInSeconds=false) => {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
    // clear countdown when date is reached
    if (diff < 0) return false;

    if (countDownInSeconds) {
      return diff;
    }

    return calculateTime(diff);
  }

export const convertTitleCaseString = (title = '') => {
  return title === '' ? '' : (title.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}))
}

export const validateNumberInput = (input) => {
  return input.match(/[0-9]/g) ? (input.match(/[0-9]/g).join('')) : ''
}


export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const addLeadingZeros = (value) => {
  value = String(value);
  while (value.length < 2) {
    value = '0' + value;
  }
  return value;
}

export const getInsituteDomain = (isClassBoard = false, isTeacher = false) => {
  const protocolScheme = constants().cookie.secure ? 'https://' : 'http://';
  const path = isClassBoard ? (isTeacher ? "/classboard/virtual_meeting" : "" ): "";
  return protocolScheme + constants().cookie.instituteDomain + path;
}

export const getParentDomain = () => {
  const protocolScheme = constants().cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants().cookie.parentDomain;
}

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1);
};
