import React, { Component } from 'react';
import Constants from '../../shared/constants';
import { isMobileDevice } from '../../helpers/Utils';
import PHONE_ICON from '../../shared/images/phone.png';
const IS_MOBILE = isMobileDevice();

class Header extends Component {
  render () {
    return(
      <div style={{ height: 60, width: '100%', boxShadow: "0px 3px 6px #4545451A", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ padding: IS_MOBILE ? "10px" : "0px 85px", fontWeight: 800 }}>
          <span style={{ cursor: 'pointer', fontSize: 24 }} onClick={() => window.location.href = Constants().landingUrl}>
            <span style={{ color: '#FF5F5F' }}>IDEA</span>
            <span style={{ color: '#454545' }}>BOARD</span>
          </span>
        </div>
      {IS_MOBILE && <div>
          <a href={`tel:${Constants().contact}`}><img style={{ width: 30, height: 30, marginRight: 10 }} alt="phoneIcon" src={PHONE_ICON} /></a>
        </div>}
        {!IS_MOBILE && <div style={{ padding: IS_MOBILE ? "10px" : "0px 85px", fontSize: 20, fontWeight: 700 }}>
        {Constants().contact}
        </div>}
      </div>);
    }
}
export default Header;
