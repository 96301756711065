import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import OtpInput from 'react-otp-input';

import { updateAuth, singleSignOn, updateTimerExpirey, validateUID } from '../actions';
import { ActivityIndicator } from './common';
import { AUTH_MODE, AUTH_CONFIG, USER_TYPE, ICON_CONFIG, PAGE_TYPES, ERROR_MESSAGES, AUTH_LIMIT_CONSTANTS } from '../shared/constants/fieldTypes';
import { validateNumberInput, validateEmail, isMobileDevice, convertConvention, convertTitleCaseString } from '../helpers/Utils';
import { Modal } from './common';
import Countdown from './countDown';
import Text from './common/Text';
import StyleConstants from '../shared/styleConstants/styles';
import { sendEvent } from '../helpers/Analytics';
import constants from '../shared/constants';
import PLAY_STORE_LOGO from '../shared/images/play_store.png';
import '../components/css/UserAuthenticationModes.css'
import { createBrowserHistory } from 'history';


const IS_MOBILE = isMobileDevice();
const deviceType = IS_MOBILE ? 'Mobile' : 'Desktop'
const tooltipPlace = IS_MOBILE ? 'top' : 'left'
const { primaryButtonColor, primaryBackgroundColor, defaultErrorMessage } = constants();
const history = createBrowserHistory({
  forceRefresh: true,
});
let mode = window.location.pathname;

const styles = {
  inputStyle: {
    maxWidth: 450,
    minWidth: IS_MOBILE ? window.screen.width - 100 : 450,
    height: 40,
    paddingLeft: 10,
    margin: "10px 0px",
    backgroundColor: primaryBackgroundColor,
  },
  passwordInputStyle: {
    maxWidth: 450,
    minWidth: IS_MOBILE ? window.screen.width - 140 : 410,
    height: 40,
    paddingLeft: 10,
    margin: "10px 0px",
    backgroundColor: primaryBackgroundColor,
  },
  boxStyle: {
    backgroundColor: primaryButtonColor,
    justifyContent: 'center',
    paddingTop: 11,
    paddingBottom: 11,
    cursor: 'pointer',
    borderRadius: 2,
    boxShadow: '0px 3px 6px 0px rgba(108,92,231,0.5)',
    marginBottom: 5,
    margin: 'auto',
    textAlign: 'center',
    marginTop: '20px',
  },
  textStyle: {
    color: '#FFFFFF',
    fontSize: 18,
    marginTop: 10,
    textAlign: 'center',
    fontWeight: 600,
    userSelect: 'none',
  },
  mainHeaderStyle: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: IS_MOBILE ? -10 : 0,
  },
  tagHeaderStyle: {
    fontSize: 14,
    textAlign: 'left',
  },
  containerStyle: {
    maxWidth: 550,
    borderRadius: 4,
    padding: 20,
    margin: 'auto',
    display: 'inline',
  },
  pointerStyle: {
    textAlign: 'center',
    color: primaryButtonColor,
    cursor: 'pointer',
    marginTop: 20,
  },
};
class UserAuthenticationModes extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      phone: '',
      instituteName: '',
      selectedAuth: null,
      formValidation: true,
      courseType: '',
      pageType: PAGE_TYPES.EMAIL_OR_PHONE,
      otp: '',
      focusOtpIndex: '',
      otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString(),
      showCountDown: false,
      showErrorModal: false,
      uidType: null,
      uidData: '',
      tooltipContent: null,
      tooltipShowType: '',
      showTrialSuccessModal: false,
      isLoader: false,
      showPassword: false,
      userType: 'Institute',
      studentFromMobile: false,
      sendOtp: true,
      isWhitelisted: false,
      badgeIdLogin: false,
    }
    this.updateNextState = this.updateNextState.bind(this);
    this.tooltipShow = this.tooltipShow.bind(this);
    this.formValidationInstitute = this.formValidationInstitute.bind(this);
    this.formValidationStudent = this.formValidationStudent.bind(this);
    this.checkSignIn = this.checkSignIn.bind(this);
  }

  updateNextState(isResendOTP = false) {
    this.setState({ isLoader: false })
    const { pageType, otp, phone, password, sendOtp, badgeIdLogin } = this.state;
    const { userType } = this.props;
    if (pageType === PAGE_TYPES.OTP && otp.length === AUTH_LIMIT_CONSTANTS.OTP_LENGTH && !isResendOTP) {
      this.props.singleSignOn({ phone_number: phone, otp, auth_type: mode, user_type: userType, sendOtp, badge_id_login: badgeIdLogin }).then((response) => {
        if (response.success && (response.user.name || response.user.name === '')) {
          if (mode === 'SIGN_IN') {
            sendEvent(`${this.state.userType}SignInSuccessful`, `${this.state.userType}SignIn`, 'Log', { phone: phone });
          } else {
            sendEvent(`${this.state.userType}SignUpSuccessful`, `${this.state.userType}SignUp`, 'Log', { phone: phone });
          }
        }
        if (!response.success) {
          const errorMessage = (response && response.message) ? response.message : ERROR_MESSAGES.INVALID_OTP;
          this.tooltipShow(errorMessage, 'otp', this.otpTooltip, { otp: "" });
          sendEvent('InvalidOTP', `${this.state.userType}}SignUp`, 'View');
        }
      });
    } else if (pageType === PAGE_TYPES.PASSWORD) {
      this.props.singleSignOn({ phone_number: phone, password, auth_type: mode, user_type: userType }, true).then((response) => {
        if (!response.success) {
          const errorMessage = (response && response.message) ? response.message : ERROR_MESSAGES.INCORRECT_PASSWORD;
          this.tooltipShow(errorMessage, 'password', this.passwordTooltip);
        }
      });
    } else {
      if (isResendOTP) {
        sendEvent('ResendOTP', `${this.state.userType}SignIn`, 'Click', { phone: phone });
        this.setState({ otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString() })
      } else {
        sendEvent('OTPRequested', `${this.state.userType}SignIn`, 'Click', { phone: phone });
      }
      this.props.updateTimerExpirey(false);
      this.props.singleSignOn({ phone_number: phone, otp, auth_type: mode, user_type: userType, send_otp: isResendOTP || this.state.sendOtp }).then((response) => {
        if (response.success && response.page_type === PAGE_TYPES.PASSWORD) {
          this.setState({ pageType: PAGE_TYPES.PASSWORD });
        } else if (response.success) {
          this.setState({ pageType: PAGE_TYPES.OTP, otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString() });
        } else if (response.success && pageType === PAGE_TYPES.PHONE) {
          this.setState({ pageType: PAGE_TYPES.OTP });
        } else {
          if (!response.success) {
            this.tooltipShow(response.message || defaultErrorMessage, 'phone', this.phoneTooltip)
          }
        }
      });
    }
  }

  componentDidMount() {
    mode = (mode === '/sign_in') ? AUTH_MODE.SIGN_IN : AUTH_MODE.SIGN_UP
    this.props.updateAuth({ mode: mode });
    const searchParams = (new URL(document.location)).searchParams;
    if (mode === AUTH_MODE.SIGN_UP) {
      sendEvent('SignUpForm', 'SignUp', 'View')
    } else if (window.location.pathname === '/sign_in') {
      if (searchParams.get('type') === USER_TYPE.STUDENT) {
        sendEvent('SignInForm', 'SignIn', 'View', { source: USER_TYPE.STUDENT })
      } else if (searchParams.get('type') === 'Institute') {
        sendEvent('SignInForm', 'SignIn', 'View', { source: USER_TYPE.INSTITUTE })
      } else {
        sendEvent('SignInForm', 'SignIn', 'View')
      }
    }
  }

  renderTrialStartModal() {
    return (
      <Modal showModal={this.state.showTrialSuccessModal} handleCloseModal={() => { this.setState({ showTrialSuccessModal: false }, () => window.location.replace(constants().qbankOnboardingUrl)); }}>
        <div style={{ textAlign: 'center', padding: 30 }}>
          <img style={{ right: 10, position: 'absolute', top: 10 }} onClick={() => { this.setState({ showTrialSuccessModal: false }, () => window.location.replace(constants().qbankOnboardingUrl)); }} src={ICON_CONFIG.CLOSE_ICON} width={8} height={8} alt="close" />
          <img src={ICON_CONFIG.TRIAL_CHECK_ICON} width={50} height={50} alt="success" />
          <div style={{ padding: 5, fontWeight: 600 }}>Trial Unlocked</div>
          <div style={{ fontSize: 12 }}>Create and enjoy 2 tests & 2 assignments for free. </div>
        </div>
      </Modal>
    )
  }

  renderInputField() {
    const { pageType, studentFromMobile } = this.state;
    if (pageType === PAGE_TYPES.PHONE && (!studentFromMobile)) {
      return (
        <div style={{ textAlign: "left" }}>
          <div ref={ref => this.phoneTooltip = ref} data-tip='tooltip'>
            <input id="phone-number" type="text"
              maxLength='10'
              style={styles.inputStyle}
              pattern={"[0-9. -]*"}
              onChange={(event) => {
                ReactTooltip.hide(this.phoneTooltip);
                this.setState({ [pageType]: event.target.value ? (mode === AUTH_MODE.SIGN_UP ? validateNumberInput(event.target.value) : event.target.value) : event.target.value })
              }}
              value={this.state[pageType]}
              placeholder="Phone Number"
              autoFocus="autofocus"
              onFocus={() => {
                sendEvent('PhoneNumberInput', 'StudentSignUp', 'Click')
              }}
              onKeyPress={(event) => event.key === 'Enter' && this.formValidationStudent(event)}

            />
          </div>
          <ReactTooltip place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        </div>
      )
    }
    if (pageType === PAGE_TYPES.PASSWORD) {
      return (
        <div>
          <div ref={ref => this.passwordTooltip = ref} data-tip='tooltip'>
            <input id="password" type="password"
              style={styles.inputStyle}
              onChange={(event) => this.setState({ [pageType]: event.target.value })}
              value={this.state[pageType]}
              autoFocus="autofocus"
              placeholder="Password"
            />
          </div>
          <ReactTooltip place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        </div>
      )
    }

    const { otp, focusOtpIndex } = this.state;
    if (this.refs['OTP_0'] && !focusOtpIndex) {
      this.refs['OTP_0'].focus();
    }
    if (pageType === PAGE_TYPES.OTP) {
      const { phone } = this.state;
      return (
        <div>
          <div style={styles.mainHeaderStyle}>Please enter the OTP</div>
          <div id="otp-input" ref={ref => this.otpTooltip = ref} data-tip='tooltip' data-for="otp-tooltip">
            <OtpInput
              value={otp}
              onChange={otp => {
                if (!isNaN(Number(otp))) {
                  this.setState({ otp }, () => {
                    if (otp.length === 1) {
                      if (mode === AUTH_MODE.SIGN_IN) {
                        sendEvent('OTPinput', `${this.state.userType}SignIn`, 'Click', { phone: phone })
                      } else {
                        sendEvent('OTPinput', `${this.state.userType}SignUp`, 'Click', { phone: phone })
                      };
                    }
                    if (otp.length === 6) {
                      this.setState({ isLoader: true, sendOtp: false }, () => { this.updateNextState() })
                    }
                  });
                }
                ReactTooltip.hide(this.otpTooltip);
              }}
              numInputs={6}
              separator={<span> &emsp; </span>}
              inputStyle={{ width: 40, height: 40 }}
            />
          </div>
          <ReactTooltip id="otp-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        </div>
      );
    }
  }

  renderUserAuthenticationModes() {
    const { selectedAuth } = this.state;
    return (
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        <div style={styles.mainHeaderStyle}>{mode === AUTH_MODE.SIGN_IN ? 'Sign in' : (!selectedAuth) ? 'I am a' : 'Sign up as'} </div>
        <div className="row justify-content-center" style={{ alignItems: 'center', width: 'max-content' }}>
          {
            AUTH_CONFIG[mode].map((authType) => {
              return (
                <div
                  className="col"
                  style={{
                    backgroundColor: authType === selectedAuth ? '#F8F7FF' : '#FFFFFF', textAlign: 'center', position: 'relative', alignItems: 'center', boxShadow: '1px 2px 6px #8B9DAF26',
                    cursor: 'pointer', margin: 10, padding: IS_MOBILE ? '0px 20px' : '0px 40px', border: authType === selectedAuth ? `1px solid ${primaryButtonColor}` : "", justifyContent: 'center',
                    borderRadius: 4, display: "inline-grid", height: IS_MOBILE ? 150 : 230
                  }}
                  key={authType}
                  onClick={() => {
                    if (USER_TYPE[authType] === 'STUDENT') {
                      this.setState({ userType: USER_TYPE.STUDENT })
                      sendEvent('SignUpAsStudent', 'InstituteSignUp', 'Click')
                      sendEvent('StudentSignupForm', 'StudentSignUp', 'View')
                    } else {
                      this.setState({ userType: 'Institute' })
                      sendEvent('SignUpAsInstitute', 'StudentSignUp', 'Click')
                      sendEvent('InstituteSignupForm', 'InstituteSignUp', 'View')
                    }
                    this.props.updateAuth({ userType: USER_TYPE[authType] });
                    this.setState({ formValidation: true, selectedAuth: authType, pageType: authType === USER_TYPE.INSTITUTE ? PAGE_TYPES.REGISTER : PAGE_TYPES.PHONE, email: '', password: '', phone: '', uidData: '', uidType: '' });
                  }}
                >
                  {authType === selectedAuth && <img src={ICON_CONFIG.CHECK_ICON} style={{ position: 'absolute', bottom: -8, right: -8, height: 25, width: 25 }} alt="selected" />}
                  <img alt={authType} style={{ width: IS_MOBILE ? '90px' : '130px', alignSelf: "flex-end", height: IS_MOBILE ? 90 : 130, display: 'flex', justifySelf: 'center' }} src={ICON_CONFIG[`${authType}${"_SELECTED"}`]} />
                  <div style={{ textTransform: 'capitalize', textAlign: 'center', width: "100%" }}>
                    <Text
                      fontWeight={StyleConstants.textWeight.bold}
                      color='#454545'
                      fontSize={22}
                      text={(authType === 'STUDENT') ? authType.toLowerCase() : 'Teacher'}
                    />
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    )
  }

  renderMultipleTypesPage() {
    const { auth: { multiUserAuthId }, userTypes, userType, blockStudentlogin } = this.props;
    const blockLogin = this.state.uidType === 'email' && userType === USER_TYPE.STUDENT;
    const isStudentAndBlockStudentLogin = blockStudentlogin && userType === USER_TYPE.STUDENT;
    return (
      <div style={{ width: 'fit-content', margin: 'auto', maxWidth: 800 }}>
        <div style={styles.mainHeaderStyle}> Log In as </div>
        <div className="row justify-content-center" style={{ alignItems: 'center', display: 'flex', width: IS_MOBILE ? 250 : 700, margin: 'auto' }} ref={ref => this.multipleUserToolTip = ref} data-tip='tooltip' data-for="multiple-user-tooltip" data-tip-disable={this.state.tooltipShowType !== 'multipleUser'}>
          <ReactTooltip id="multiple-user-tooltip" place="top" type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
          {
            userTypes.map((userType) => {
              const invalidType = this.state.uidType === 'email' && userType.user_type === USER_TYPE.STUDENT;
              const selectedAuth = userType.id === multiUserAuthId
              const isActive = userType.active
              if (!invalidType) {
                return (
                  <div
                    className="col"
                    style={{
                      backgroundColor: isActive ? selectedAuth ? '#F8F7FF' : '#FFFFFF' : '#ffe6e6', textAlign: 'center', position: 'relative', alignItems: 'center', boxShadow: '1px 2px 6px #8B9DAF26', maxWidth: 300,
                      cursor: 'pointer', margin: 10, padding: IS_MOBILE ? '0px 20px' : '0px 40px', border: isActive ? selectedAuth ? `1px solid ${primaryButtonColor}` : "" : '0.5px solid #e60000', justifyContent: 'center',
                      borderRadius: 4, display: "inline-grid", height: IS_MOBILE ? 150 : 230, elevation: selectedAuth ? 0 : 5, opacity: isActive ? 1 : 0.4
                    }}
                    key={userType.id}
                    onClick={() => {
                      ReactTooltip.hide(this.multipleUserToolTip);
                      this.setState({ userType: userType.user_type.toLowerCase() })
                      if (isActive) {
                        this.props.updateAuth({ multiUserAuthId: userType.id })
                        if (userType.user_type.toLocaleUpperCase() === 'INSTITUTE') {
                          this.props.updateAuth({ userType: USER_TYPE.INSTITUTE });
                        } else if (userType.user_type.toLocaleUpperCase() === 'PARENT') {
                          this.props.updateAuth({ userType: USER_TYPE.PARENT });
                        } else {
                          this.props.updateAuth({ userType: USER_TYPE.STUDENT });
                        }
                        if (isStudentAndBlockStudentLogin) {
                          this.tooltipShow(ERROR_MESSAGES.BLOCK_STUDENT_LOGIN, 'multipleUser', this.multipleUserToolTip);
                        }
                      } else {
                        this.props.updateAuth({ multiUserAuthId: null })
                        this.tooltipShow(ERROR_MESSAGES.ACCOUNT_INACTIVE, 'multipleUser', this.multipleUserToolTip);
                      }

                    }}
                  >
                    {selectedAuth && <img src={ICON_CONFIG.CHECK_ICON} style={{ position: 'absolute', bottom: -8, right: -8, height: 25, width: 25 }} alt="selected" />}
                    <img alt={userType.id} style={{ width: IS_MOBILE ? '90px' : '130px', alignSelf: "flex-end", height: IS_MOBILE ? 90 : 130, display: 'flex', justifySelf: 'center' }} src={ICON_CONFIG[`${userType.user_type.toLocaleUpperCase()}${"_SELECTED"}`]} />
                    <div style={{ textTransform: 'capitalize', textAlign: 'center', width: "100%" }}>
                      <Text
                        fontWeight={StyleConstants.textWeight.bold}
                        color={isActive ? '#454545' : '#e60000'}
                        fontSize={isActive ? 22 : 12}
                        text={isActive ? convertTitleCaseString(userType.user_type) : 'Account Suspended'}
                      />
                    </div>
                    {userType.name !== 'IB' && // showing IB as a institute name for B2C students
                      <div style={{ marginTop: -15 }}>
                        <Text
                          fontWeight={StyleConstants.textWeight.bold}
                          fontSize={14}
                          text={convertTitleCaseString(userType.name)}
                        />
                      </div>
                    }
                  </div>
                );
              }
              return <div />;
            })
          }
        </div>
        <div id="multiple-accounts" style={{ ...styles.boxStyle, maxWidth: 300, cursor: blockLogin ? "not-allowed" : "pointer", opacity: (blockLogin || isStudentAndBlockStudentLogin) ? 0.5 : 1 }} onClick={(event) => {
          if (multiUserAuthId) {
            if (blockLogin) {
              this.tooltipShow(ERROR_MESSAGES.NO_USER_TYPE, 'multipleUser', this.multipleUserToolTip);
            } else if (isStudentAndBlockStudentLogin) {
              this.tooltipShow(ERROR_MESSAGES.BLOCK_STUDENT_LOGIN, 'multipleUser', this.multipleUserToolTip);
            } else {
              if (IS_MOBILE && userType === USER_TYPE.STUDENT) {
                window.location.assign(`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetOurAppFromSignUp%26device_type%3D${deviceType}`)
              } else {
                if (this.state.uidType === 'phone') {
                  this.setState({ pageType: PAGE_TYPES.OTP, sendOtp: true, otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString() })
                } else {
                  this.setState({ pageType: PAGE_TYPES.PASSWORD });
                }
                this.updateNextState();
              }
            }
          } else {
            this.tooltipShow(ERROR_MESSAGES.ACTIVE_ACCOUNTS, 'multipleUser', this.multipleUserToolTip);
          }

        }}>
          <span id="user-id-login-button" style={styles.textStyle}>{(this.state.isLoader) ? <ActivityIndicator /> : (!IS_MOBILE || userType !== USER_TYPE.STUDENT) ? 'Continue' : 'Get our App'}</span>
        </div>
      </div>
    )
  }

  renderAuthenticationType() {
    const { userType } = this.props;
    return (
      <div>
        <div>
          {mode === AUTH_MODE.SIGN_IN && userType !== USER_TYPE.PARENT && (
            <div id="sign-in" style={{ marginTop: 20, textAlign: 'center' }}>Don't have an account? <span style={styles.pointerStyle} onClick={(e) => {
              sendEvent('CreateAccountButton', `${this.state.userType}SignIn`, 'Click')
              history.push(`/sign_up?type=${this.state.userType}`);
            }}>Create one</span></div>
          )}
          {mode === AUTH_MODE.SIGN_UP && (
            <div id="try-signing-in" style={{ marginTop: 20, textAlign: 'center' }}>Have an Account? <span style={styles.pointerStyle} onClick={(e) => {
              sendEvent('AlreadyHaveAnAccount', `${this.state.userType}SignUp`, 'Click')
              history.push(`/sign_in?type=${this.state.userType}`);
            }}>Log in</span></div>
          )}
        </div>
        {(this.state.pageType === PAGE_TYPES.PHONE || this.state.pageType === PAGE_TYPES.OTP) && <div >
          <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetItOnGooglePlayFromOTPpage%26device_type%3D${deviceType}`} target="_blank">
            <img alt="Get it on Google Play" onClick={() => {
              sendEvent('GetItOnGooglePlay', 'StudentSignUp', 'Click')
            }} style={{ width: 150, marginTop: 20 }} src={PLAY_STORE_LOGO} />
          </a>
        </div>}
      </div>
    )
  }

  formValidationInstitute(e) {
    const { email, instituteName, phone, password } = this.state;
    this.setState({ isLoader: true })
    e.preventDefault();
    if (mode === AUTH_MODE.SIGN_UP && !(instituteName && instituteName.length > 2)) {
      this.tooltipShow(ERROR_MESSAGES.INSTITUTE_NAME_MIN_LENGTH, 'name', this.nameTooltip);
    } else if (!validateEmail(email)) {
      this.tooltipShow(ERROR_MESSAGES.INVALID_EMAIL, 'email', this.emailTooltip);
    } else if (mode === AUTH_MODE.SIGN_UP && phone.length < AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH) {
      this.tooltipShow(ERROR_MESSAGES.INVALID_MOBILE_NUMBER, 'phone', this.phoneTooltip);
    } else if (password.length < AUTH_LIMIT_CONSTANTS.PASSWORD_LENGTH) {
      this.tooltipShow(ERROR_MESSAGES.PASSWORD_MIN_LENGTH, 'password', this.passwordTooltip);
    } else if (mode === AUTH_MODE.SIGN_IN) {
      this.props.singleSignOn({ email, password, auth_type: AUTH_MODE.SIGN_IN, user_type: USER_TYPE.INSTITUTE }).then((response) => {
        if (!response.success) {
          const errorMessage = (response && response.message) ? response.message : ERROR_MESSAGES.INCORRECT_PASSWORD;
          this.tooltipShow(errorMessage, 'password', this.passwordTooltip);
        } else {
          sendEvent('InstituteSignInSuccessful', 'InstituteSignIn', 'Log', { instituteName })
        }
      });
    } else if (mode === AUTH_MODE.SIGN_UP) {
      const searchParams = (new URL(document.location)).searchParams;
      let info = searchParams.get('info')
      if (info === null) {
        info = 'WebAppDirect'
      }
      this.props.singleSignOn({ email, password, phone_number: phone, institute_name: instituteName, auth_type: AUTH_MODE.SIGN_UP, user_type: USER_TYPE.INSTITUTE, info }).then((response) => {
        if (!response.success) {
          this.tooltipShow(response.message, response.page_type, this[`${response.page_type}Tooltip`]);
        } else {
          sendEvent('InstituteSignUpSuccessful', 'InstituteSignUp', 'Log', { instituteName: instituteName })
          window.location.replace(constants().qbankOnboardingUrl);
        }
      });
    }
  }

  formValidationStudent(e) {
    const { pageType, phone } = this.state;
    if (pageType === PAGE_TYPES.OTP) {
      sendEvent('ContinueAfterOTP', `${this.state.userType}SignIn`, 'Log', { phone })
    } else {
      sendEvent('ContinueAfterphone', `${this.state.userType}SignUp`, 'Click', { phone })
    }
    e.preventDefault();
    if (pageType === PAGE_TYPES.PASSWORD) {
      if (!validateNumberInput(this.state.password)) {
        this.tooltipShow(ERROR_MESSAGES.INCORRECT_PASSWORD, 'password', this.passwordTooltip);
      } else {
        this.updateNextState()
      }
      return;
    }
    if (phone.length < AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH) {
      this.tooltipShow(ERROR_MESSAGES.INVALID_MOBILE_NUMBER, 'phone', this.phoneTooltip);
    }
    else {
      this.setState({ isLoader: true })
      this.updateNextState()
    }

  }

  checkSignIn(e) {
    e.preventDefault();
    const { uidData } = this.state;
    if (uidData.indexOf('@') > -1) {
      this.setState({ uidType: 'email' });
      if (!validateEmail(uidData)) {
        this.tooltipShow(ERROR_MESSAGES.INVALID_EMAIL, 'uid', this.uidTooltip);
      } else {
        this.props.validateUID({ uidData, uidType: 'email' }).then((response) => {
          if (response.success) {
            if (!response.is_valid) {
              let messageContent = (!!response.message) ? response.message : ERROR_MESSAGES.UNREGISTERED_EMAIL;
              this.tooltipShow(messageContent, 'uid', this.uidTooltip);
            } else {
              if (response.types.length > 1) {
                let userTypes = [];
                let currentUserType = ''
                if (response.block_student_login) {
                  this.props.updateAuth({ blockStudentlogin: response.block_student_login })
                }
                response.types.map((userType) => {
                  const type = convertConvention(userType.user_type)
                  userType.user_type = type
                  if (userType.id === userType.current_membership_id) {
                    this.setState({ userType: type })
                    currentUserType = type;
                  }
                  return userTypes.push(userType)
                })
                const payload = { userTypes, userType: currentUserType, multiUserAuthId: response.types[0].current_membership_id }
                this.setState({ pageType: PAGE_TYPES.MULTIPLE_TYPES, email: uidData, sendOtp: true })
                this.props.updateAuth(payload);
                this.setState({ isLoader: false })
                return;
              }
              sendEvent('PasswordPage', 'InstituteSignIn', 'View');
              this.setState({ pageType: PAGE_TYPES.PASSWORD, email: uidData });
              this.props.updateAuth({ userType: USER_TYPE.INSTITUTE });
              this.setState({ isLoader: false })
            }
          }
        });
      }
    } else if (/^\d+$/.test(uidData) && uidData.length === AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH) {
      this.setState({ uidType: 'phone' });
      this.props.validateUID({ uidData, uidType: 'phone' }).then((response) => {
        if (response.success) {
          if (response.message) {
            this.tooltipShow(response.message, 'uid', this.uidTooltip);
            return
          }
          if (!response.is_valid) {
            let messageContent = (!!response.message) ? response.message : ERROR_MESSAGES.UNREGISTERED_MOBILE_NUMBER;
            this.tooltipShow(messageContent, 'uid', this.uidTooltip);
          } else {
            this.setState({ isWhitelisted: response.is_whitelisted })
            if (response.is_student) {
              this.setState({ userType: USER_TYPE.STUDENT })
              if (IS_MOBILE) {
                this.setState({ studentFromMobile: true })
              }
            } else if (response.is_parent) {
              this.setState({ userType: USER_TYPE.PARENT })
            } else {
              this.setState({ userType: USER_TYPE.INSTITUTE })
            }
            if (IS_MOBILE && this.state.userType === USER_TYPE.STUDENT) {
              this.setState({ isLoader: false })
            } else {
              if (response.types.length > 1) {
                let data = { multiUserAuthId: response.types[0].current_membership_id };
                if (response.block_student_login) {
                  data = { ...data, blockStudentlogin: response.block_student_login }
                }
                this.props.updateAuth(data);
                let userTypes = [];
                let currentUserType = ''
                response.types.map((userType) => {
                  const type = convertConvention(userType.user_type)
                  userType.user_type = type
                  if (userType.id === userType.current_membership_id) {
                    this.setState({ userType: type })
                    currentUserType = type;
                  }
                  return userTypes.push(userType)
                })
                const payload = { userTypes, userType: currentUserType }
                this.setState({ pageType: PAGE_TYPES.MULTIPLE_TYPES, phone: uidData, sendOtp: true })
                this.props.updateAuth(payload);
              } else {
                if (this.state.userType !== USER_TYPE.STUDENT || !IS_MOBILE) {
                  sendEvent('EnterOTPForm', `${this.state.userType}SignIn`, 'View')
                  this.setState({ pageType: PAGE_TYPES.OTP, otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString(), phone: uidData });
                }
              }
              if (this.state.userType === 'INSTITUTE') {
                this.props.updateAuth({ userType: USER_TYPE.INSTITUTE });
              } else if (this.state.userType === 'STUDENT') {
                this.props.updateAuth({ userType: USER_TYPE.STUDENT });
              } else {
                this.props.updateAuth({ userType: USER_TYPE.PARENT });
              }
              this.setState({ isLoader: false })
            }
          }
        } else {
          this.tooltipShow(response.message || defaultErrorMessage, 'uid', this.uidTooltip);
        }
      });
    } else {
      this.setState({ uidType: 'badgeId' });
      this.props.validateUID({ uidData, uidType: 'badgeId' }).then((response) => {
        if (response.success) {
          if (!response.is_valid) {
            let messageContent = (!!response.message) ? response.message : ERROR_MESSAGES.UNREGISTERED_ID;
            this.tooltipShow(messageContent, 'uid', this.uidTooltip);
          } else {
            this.setState({ pageType: PAGE_TYPES.OTP, badgeIdLogin: true, phone: uidData });
            this.props.updateAuth({ userType: USER_TYPE.STUDENT });
            this.setState({ isLoader: false })
          }
        }
      });
    }
  }

  renderInstituteFormValidation() {
    const { email, instituteName, phone, password, showPassword } = this.state;
    if (phone && mode === AUTH_MODE.SIGN_IN) {
      return this.renderOtpTypeValidation();
    };
    return (
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        {mode === AUTH_MODE.SIGN_UP &&
          <div>
            <div ref={ref => this.nameTooltip = ref} data-tip='tooltip' data-for="name-tooltip" data-tip-disable={this.state.tooltipShowType !== 'name'}>
              <input id="institute-name"
                type="text"
                maxLength="100"
                style={styles.inputStyle}
                placeholder="Institute Name"
                onFocus={() => {
                  sendEvent('InstituteNameInput', 'InstituteSignUp', 'Click');
                }}
                onChange={(event) => {
                  ReactTooltip.hide(this.nameTooltip);
                  this.setState({ instituteName: event.target.value })
                }}
                value={instituteName}
                onKeyPress={(event) => event.key === 'Enter' && this.formValidationInstitute(event)}
                autoFocus
              />
            </div>
            <ReactTooltip id="name-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
          </div>
        }
        {mode === AUTH_MODE.SIGN_UP &&
          <div>
            <div ref={ref => this.emailTooltip = ref} data-tip='tooltip' data-for="email-tooltip" data-tip-disable={this.state.tooltipShowType !== 'email'}>
              <input id="institute-email" type="text" maxLength="40"
                style={styles.inputStyle}
                placeholder="Email"
                onFocus={() => {
                  sendEvent('EmailInput', 'InstituteSignUp', 'Click');
                }}
                onChange={(event) => {
                  ReactTooltip.hide(this.emailTooltip);
                  this.setState({ email: event.target.value })
                }}
                value={email}
                onKeyPress={(event) => event.key === 'Enter' && this.formValidationInstitute(event)}
              />
            </div>
            <ReactTooltip id="email-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
          </div>
        }
        {mode === AUTH_MODE.SIGN_UP &&
          <div>
            <div ref={ref => this.phoneTooltip = ref} data-tip='tooltip' data-for="phone-tooltip" data-tip-disable={this.state.tooltipShowType !== 'phone'}>
              <input id="institute-phone-number" type="text" maxLength={AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH}
                style={styles.inputStyle}
                pattern={"[0-9. -]*"}
                placeholder="Mobile"
                onFocus={() => {
                  sendEvent('MobileInput', 'InstituteSignUp', 'Click');
                }}
                onChange={(event) => {
                  ReactTooltip.hide(this.phoneTooltip);
                  this.setState({ phone: validateNumberInput(event.target.value) })
                }}
                value={phone}
                onKeyPress={(event) => event.key === 'Enter' && this.formValidationInstitute(event)}
              />
            </div>
            <ReactTooltip id="phone-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
          </div>
        }
        <div>
          <div ref={ref => this.passwordTooltip = ref} data-tip='tooltip' data-for="password-tooltip" data-tip-disable={this.state.tooltipShowType !== 'password'}>
            <input id="institute-password" type={(showPassword) ? "text" : "Password"}
              style={styles.passwordInputStyle}
              placeholder={`${mode === AUTH_MODE.SIGN_UP ? "New " : ""}Password`}
              onChange={(event) => {
                ReactTooltip.hide(this.passwordTooltip);
                this.setState({ password: event.target.value })
              }}
              onFocus={() => {
                if (mode === AUTH_MODE.SIGN_UP) {
                  sendEvent('NewPasswordInput', 'InstituteSignUp', 'Click');
                } else {
                  sendEvent('PasswordInput', 'InstituteSignIn', 'Click');
                }

              }}
              value={password}
              autoComplete="current-password"
              onKeyPress={(event) => event.key === 'Enter' && this.formValidationInstitute(event)}
              autoFocus={mode === AUTH_MODE.SIGN_IN}
            />
            <img style={{ cursor: 'pointer' }} src={(showPassword) ? ICON_CONFIG.SHOW_PASSWORD : ICON_CONFIG.HIDE_PASSWORD} onClick={() => { this.setState({ showPassword: !showPassword }) }} width={35} height={35} alt="success" />
          </div>
          <ReactTooltip id="password-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        </div>
        {mode === AUTH_MODE.SIGN_IN && <p style={{ textAlign: 'right', color: primaryButtonColor, cursor: 'pointer' }} onClick={(e) => {
          sendEvent('Reset Password', 'InstituteSignIn', 'Click')
          history.push('/forgot_password')
        }
        }>Reset password</p>}
        <div id="institute-sign-up" style={styles.boxStyle} onClick={(event) => {
          if (mode === AUTH_MODE.SIGN_UP) {
            sendEvent('InstituteSignUpButton', 'InstituteSignUp', 'Click', { instituteName: instituteName, phone: phone, email: email })
          } else {
            sendEvent('ContinueAfterPasswordButton', 'InstituteSignIn', 'Click')
          }
          this.formValidationInstitute(event)
        }}>
          <span id="user-password-login-button" style={styles.textStyle}>{(this.state.isLoader) ? <ActivityIndicator /> : mode === AUTH_MODE.SIGN_UP ? 'Create 2 papers for free!' : 'Continue'}</span>
        </div>
        {this.renderAuthenticationType()}
      </div>
    );
  }

  renderOtpTypeValidation() {
    const { hasTimerExpired } = this.props;
    const { pageType, otpTime, isLoader, isWhitelisted, badgeIdLogin } = this.state;
    return (
      <div style={styles.containerStyle}>
        <div style={{ width: IS_MOBILE ? 300 : 'fit-content', margin: 'auto' }}>
          {this.renderInputField(pageType)}
          {pageType === PAGE_TYPES.OTP &&
            <div style={{ marginTop: 10, flexDirection: "row", justifyContent: 'space-around', marginBottom: 20, alignItems: 'center' }} className="row" >
              {
                pageType === PAGE_TYPES.OTP &&
                <div id="edit-phone-number" style={{ fontSize: 12, textAlign: 'left' }}>{this.state.phone} (<span style={{ color: StyleConstants.color.primary, cursor: 'pointer' }} onClick={() => {
                  sendEvent('OTPchange', `${this.state.userType}SignIn`, 'Click')
                  this.setState({ userType: null, pageType: PAGE_TYPES.EMAIL_OR_PHONE })
                }}>change</span>)</div>
              }
              {!hasTimerExpired && !isWhitelisted && !badgeIdLogin &&
                <span style={{ fontSize: 12 }}>
                  Resend OTP
                <Countdown date={otpTime} />
                </span>
              }
              {hasTimerExpired && !isWhitelisted &&
                <div id='Resend-OTP-Button' style={{ cursor: 'pointer' }} onClick={() => this.updateNextState(true)}>
                  <Text
                    fontWeight={StyleConstants.textWeight.semiBold}
                    color={StyleConstants.color.primary}
                    text="Resend OTP"
                  />
                </div>
              }
            </div>
          }
          {(this.state.studentFromMobile) ?
            <div style={styles.boxStyle}>
              <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetOurAppFromSignUp%26device_type%3D${deviceType}`} target="_blank">
                <span style={styles.textStyle}>{(isLoader) ? <ActivityIndicator /> : 'Get our App'}</span>
              </a>
            </div> :
            <div
              id="parent-login-button"
              style={styles.boxStyle} onClick={(event) => {
                if (this.state.pageType === PAGE_TYPES.OTP && this.state.otp.length < 6) {
                  this.tooltipShow(ERROR_MESSAGES.NO_OTP, 'otp', this.otpTooltip, { otp: "" });
                } else {
                  this.formValidationStudent(event)
                }
              }}>
              <span style={styles.textStyle}>{(isLoader) ? <ActivityIndicator /> : 'Continue'}</span>
            </div>
          }
          {this.renderAuthenticationType()}
        </div>
      </div>
    );
  }

  renderUserAuthenticationForm() {
    const { selectedAuth } = this.state;
    const { userType } = this.props;
    if (selectedAuth || mode === AUTH_MODE.SIGN_IN) {
      if (userType === USER_TYPE.INSTITUTE) {
        return (this.renderInstituteFormValidation());
      } else if (userType === USER_TYPE.STUDENT || userType === USER_TYPE.PARENT) {
        return (this.renderOtpTypeValidation());
      }
    }
  }

  renderLoginForm() {
    const { uidData, studentFromMobile } = this.state;
    return (
      <div style={{ width: 'fit-content', borderRadius: 4, padding: 30, margin: 'auto' }}>
        <div style={styles.mainHeaderStyle}>Sign In</div>
        <div ref={ref => this.uidTooltip = ref} data-tip='tooltip'>
          <input id="uid" type="text" maxLength="40"
            style={{ ...styles.inputStyle }}
            placeholder="Email or Mobile/Login ID"
            onChange={(event) => {
              if (IS_MOBILE && event.target.value.length < AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH && studentFromMobile) {
                this.props.updateAuth({ userType: USER_TYPE.INSTITUTE });
                this.setState({ studentFromMobile: false })
              }
              ReactTooltip.hide(this.UNREGISTERED_ID)
              this.setState({ uidData: event.target.value });
            }}
            onFocus={() => {
              sendEvent('EmailOrMobileInput', `SignIn`, 'Click')
            }}
            onKeyPress={(event) => event.key === 'Enter' && this.checkSignIn(event)}
            value={uidData}
            autoFocus
          />
        </div>
        <ReactTooltip place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        {(this.state.studentFromMobile) ?
          <div style={styles.boxStyle}>
            <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetOurAppFromSignIn%26device_type%3D${deviceType}`} target="_blank">
              <span style={styles.textStyle}>{'Get our App'}</span>
            </a>
          </div> :
          <div id="user-id-login-button" style={styles.boxStyle} onClick={(event) => {
            this.setState({ isLoader: true })
            sendEvent('ContinueButton', `SignIn`, 'Click', { uidData })
            this.checkSignIn(event)
          }}>
            <span style={styles.textStyle}>{(this.state.isLoader) ? <ActivityIndicator /> : 'Continue'}</span>
          </div>
        }
        <div>
          <div id="try-signing-up" style={{ marginTop: 20, textAlign: 'center' }}>Don't have an account? <span style={styles.pointerStyle} onClick={(e) => {
            sendEvent('CreateAccountButton', `${this.state.userType}SignIn`, 'Click')
            sendEvent('InstituteSignUp', 'InstituteSignUp', 'View')
            history.push(`/sign_up`);
          }}>Create one</span></div>
        </div>
        {<div>
          <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3DibWebsite%26utm_term%3DgetItOnGooglePlay%26device_type%3D${deviceType}`} target="_blank">
            <img alt="Get it on Google Play" onClick={() => {
              sendEvent('GetItOnGooglePlay', 'Student SignIn', 'Click')
            }} style={{ width: 150, marginTop: 20 }} src={PLAY_STORE_LOGO} />
          </a>
        </div>}
      </div>
    )
  }

  tooltipShow(tooltipContent, tooltipShowType, tooltip, additionalStateProps = {}) {
    this.setState({ tooltipContent, tooltipShowType, ...additionalStateProps, isLoader: false }, () => {
      ReactTooltip.show(tooltip);
    });
  }

  render() {
    const { pageType } = this.state;
    const { userType } = this.props;
    if (userType === 'STUDENT' && IS_MOBILE) {
      this.setState({ studentFromMobile: true })
    }
    return (
      <div className="row" style={{ textAlign: 'center', justifyContent: 'center', width: 'fit-content', margin: 'auto', border: '1px solid #E4E7EE', padding: '0px 30px 0px 30px' }}>
        <div>
          <div style={styles.containerStyle}>
            {mode === AUTH_MODE.SIGN_UP && pageType !== PAGE_TYPES.OTP && this.renderUserAuthenticationModes()}
            {mode === AUTH_MODE.SIGN_IN && pageType === PAGE_TYPES.MULTIPLE_TYPES && this.renderMultipleTypesPage()}
            {(mode === AUTH_MODE.SIGN_UP || pageType === PAGE_TYPES.PASSWORD || pageType === PAGE_TYPES.OTP) && this.renderUserAuthenticationForm()}
            {mode === AUTH_MODE.SIGN_IN && pageType === PAGE_TYPES.EMAIL_OR_PHONE && this.renderLoginForm()}
            {this.renderTrialStartModal()}
          </div>
        </div>
      </div>
    )
  }
}

UserAuthenticationModes.defaultProps = {
  userType: null,
}

UserAuthenticationModes.propTypes = {
  userType: PropTypes.number,
  updateAuth: PropTypes.func.isRequired,
  userTypes: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  singleSignOn: PropTypes.func.isRequired,
  updateTimerExpirey: PropTypes.func.isRequired,
  errorType: PropTypes.number,
  toastMessage: PropTypes.string,
  validateUID: PropTypes.func.isRequired,
  hasTimerExpired: PropTypes.bool,
  pageType: PropTypes.string,
  blockStudentlogin: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
  userType: auth.userType,
  userTypes: auth.userTypes,
  errorType: auth.errorType,
  hasTimerExpired: auth.hasTimerExpired,
  toastMessage: auth.toastMessage,
  blockStudentlogin: auth.blockStudentlogin,
});

export default connect(mapStateToProps, { updateAuth, singleSignOn, updateTimerExpirey, validateUID })(UserAuthenticationModes);