import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import OtpInput from 'react-otp-input';

import { ActivityIndicator } from './common';
import { isMobileDevice } from '../helpers/Utils';
import constants from '../shared/constants';
import { validateUID, updateTimerExpirey, singleSignOn } from '../actions';
import Text from './common/Text';
import PLAY_STORE_LOGO from '../shared/images/play_store.png';
import StyleConstants from '../shared/styleConstants/styles';
import Countdown from './countDown';
import { PAGE_TYPES, AUTH_LIMIT_CONSTANTS, ERROR_MESSAGES, USER_TYPE, AUTH_MODE } from '../shared/constants/fieldTypes';

const IS_MOBILE = isMobileDevice();
const deviceType = IS_MOBILE ? 'Mobile' : 'Desktop'
const tooltipPlace = IS_MOBILE ? 'top' : 'left'
const { primaryBackgroundColor, primaryButtonColor } = constants();
const styles = {
  inputStyle: {
    maxWidth: 450,
    minWidth: IS_MOBILE ? window.screen.width - 100 : 450,
    height: 40,
    paddingLeft: 10,
    margin: "10px 0px",
    backgroundColor: primaryBackgroundColor,
  },
  boxStyle: {
    backgroundColor: primaryButtonColor,
    justifyContent: 'center',
    paddingTop: 11,
    paddingBottom: 11,
    cursor: 'pointer',
    borderRadius: 2,
    boxShadow: '0px 3px 6px 0px rgba(108,92,231,0.5)',
    marginBottom: 5,
    margin: 'auto',
    textAlign: 'center',
    marginTop: '20px',
  },
  mainHeaderStyle: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: IS_MOBILE ? -10 : 0,
  },
  textStyle: {
    color: '#FFFFFF',
    fontSize: 18,
    marginTop: 10,
    textAlign: 'center',
    fontWeight: 600,
    userSelect: 'none',
  },
  containerStyle: {
    maxWidth: 550,
    borderRadius: 4,
    padding: 30,
    display: 'inline',
  },
};

class StudentLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      otp: '',
      isLoader: false,
      tooltipContent: null,
      tooltipShowType: '',
      otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString(),
      pageType: PAGE_TYPES.PHONE,
      userType: '',
      studentFromMobile: false,
      isWhitelisted: false,
    };
    this.tooltipShow = this.tooltipShow.bind(this);
    this.renderPhoneField = this.renderPhoneField.bind(this);
    this.renderOTPField = this.renderOTPField.bind(this);
  }

  tooltipShow(tooltipContent, tooltipShowType, tooltip, additionalStateProps = {}) {
    this.setState({ tooltipContent, tooltipShowType, ...additionalStateProps }, () => {
      ReactTooltip.show(tooltip);
    });
  }

  checkSignIn(e) {
    e.preventDefault();
    const { phone } = this.state;
    if (IS_MOBILE) {
      this.setState({ studentFromMobile: true, isLoader: false })
    } else if (phone.length < AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH) {
      this.tooltipShow(ERROR_MESSAGES.INVALID_MOBILE_NUMBER, 'phone', this.phoneTooltip);
    } else {
      this.props.validateUID({ uidData: phone, uidType: 'phone', fromStudentLogin: true }).then((response) => {
        if (response.success) {
          if (!response.is_valid) {
            if (response.message) {
              this.tooltipShow(response.message, 'phone', this.phoneTooltip)
            } else {
              this.tooltipShow(ERROR_MESSAGES.UNREGISTERED_MOBILE_NUMBER, 'phone', this.phoneTooltip);
            }
          } else {
            if (response.is_student) {
              this.setState({ userType: USER_TYPE.STUDENT, pageType: PAGE_TYPES.OTP, otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString(), isWhitelisted: response.is_whitelisted });
            } else {
              this.tooltipShow(ERROR_MESSAGES.NOT_A_STUDENT, 'phone', this.phoneTooltip);
            }
          }
        }
        this.setState({ isLoader: false })
      });
    }
  }

  updateNextState(resendOTP = false) {
    const { phone, otp } = this.state;
    this.props.singleSignOn({ phone_number: phone, otp, auth_type: AUTH_MODE.SIGN_IN, user_type: USER_TYPE.STUDENT, send_otp: resendOTP }).then((response) => {
      if (!response.success) {
        this.setState({ isLoader: false });
        this.tooltipShow(ERROR_MESSAGES.INVALID_OTP, 'otp', this.otpTooltip, { otp: "" });
      } else {
        this.setState({ phone: '', otp: '', isLoader: false, tooltipContent: null, tooltipShowType: '', userType: '', studentFromMobile: false, isWhitelisted: false })
      }
    });
  }

  renderPhoneField() {
    const { phone, studentFromMobile } = this.state;
    return (
      <div style={{ width: 'fit-content', borderRadius: 4, padding: 30, margin: 'auto' }}>
        <div style={styles.mainHeaderStyle}>Sign In</div>
        <div ref={ref => this.phoneTooltip = ref} data-tip='tooltip'>
          <input id="uid" type="text" maxLength="40"
            style={{ ...styles.inputStyle }}
            placeholder="Enter Mobile number or Login ID"
            onChange={(event) => {
              if (studentFromMobile) {
                this.setState({ studentFromMobile: false });
              }
              ReactTooltip.hide(this.phoneTooltip)
              if (!isNaN(event.target.value) && event.target.value.length <= AUTH_LIMIT_CONSTANTS.MOBILE_NUMBER_LENGTH) {
                this.setState({ phone: event.target.value });
              }
            }}
            onKeyPress={(event) => event.key === 'Enter' && this.checkSignIn(event)}
            value={phone}
            autoFocus
          />
        </div>
        <ReactTooltip place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        {
          <div id="user-id-login-button" style={styles.boxStyle} onClick={(event) => {
            this.setState({ isLoader: true })
            this.checkSignIn(event)
          }}>
            <span style={styles.textStyle}>{(this.state.isLoader) ? <ActivityIndicator /> : (this.state.studentFromMobile) ? 'Get our App' : 'Continue'}</span>
          </div>
        }
        {<div>
          <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3DibWebsite%26utm_term%3DgetItOnGooglePlay%26device_type%3D${deviceType}`} target="_blank">
            <img alt="Get it on Google Play" onClick={() => {
            }} style={{ width: 150, marginTop: 20 }} src={PLAY_STORE_LOGO} />
          </a>
        </div>}
      </div>
    );
  }

  renderOTPField() {
    const { otp, pageType, isWhitelisted, otpTime, isLoader } = this.state;
    const { hasTimerExpired } = this.props;
    return (
      <div style={styles.containerStyle}>
        <div style={styles.mainHeaderStyle}>Please enter the OTP</div>
        <div id="otp-input" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }} ref={ref => this.otpTooltip = ref} data-tip='tooltip' data-for="otp-tooltip">
          <ReactTooltip id="otp-tooltip" place={tooltipPlace} type="error" effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
          <OtpInput
            value={otp}
            onChange={otp => {
              if (!isNaN(Number(otp))) {
                this.setState({ otp }, () => {
                  if (otp.length === 6) {
                    this.setState({ isLoader: true })
                    this.updateNextState()
                  }
                });
              }
              ReactTooltip.hide(this.otpTooltip);
            }}
            numInputs={6}
            separator={<span> &emsp; </span>}
            inputStyle={{ width: 40, height: 40 }}
          />
        </div>
        <div style={{ margin: 'auto' }}>
          {pageType === PAGE_TYPES.OTP &&
            <div style={{ marginTop: 10, flexDirection: "row", justifyContent: 'space-around', marginBottom: 20, alignItems: 'center' }} className="row" >
              {
                pageType === PAGE_TYPES.OTP &&
                <div id="edit-phone-number" style={{ fontSize: 12, textAlign: 'left' }}>
                  {this.state.phone} (<span style={{ color: StyleConstants.color.primary, cursor: 'pointer' }} onClick={() => {
                    this.setState({ pageType: PAGE_TYPES.PHONE, otp: '' })
                  }}>change</span>)</div>
              }
              {!hasTimerExpired && !isWhitelisted &&
                <span style={{ fontSize: 12 }}>
                  Resend OTP
                <Countdown date={otpTime} />
                </span>
              }
              {hasTimerExpired && !isWhitelisted &&
                <div id='Resend-OTP-Button' style={{ cursor: 'pointer' }} onClick={() => {
                  this.setState({ otpTime: new Date(new Date().setSeconds(new Date().getSeconds() + AUTH_LIMIT_CONSTANTS.OTP_EXPIRY_DURATION)).toString() })
                  this.props.updateTimerExpirey(false);
                  this.updateNextState(true)
                }
                }
                >
                  <Text
                    fontWeight={StyleConstants.textWeight.semiBold}
                    color={StyleConstants.color.primary}
                    text="Resend OTP"
                  />
                </div>
              }
            </div>
          }
          {(this.state.studentFromMobile) ?
            <div style={styles.boxStyle}>
              <a href={`${constants().playStoreUrl}=utm_source%3DibWebsite%26utm_medium%3Daccounts%26utm_term%3DgetOurAppFromSignUp%26device_type%3D${deviceType}`} target="_blank">
                <span style={styles.textStyle}>{(isLoader) ? <ActivityIndicator /> : 'Get our App'}</span>
              </a>
            </div> :
            <div
              style={styles.boxStyle} onClick={() => {
                this.updateNextState()
              }}>
              <span style={styles.textStyle}>{(isLoader) ? <ActivityIndicator /> : 'Continue'}</span>
            </div>
          }
        </div>
      </div>
    );
  }


  render() {
    const { pageType } = this.state;
    return (
      <div className="row" style={{ textAlign: 'center', justifyContent: 'center', width: 'fit-content', margin: 'auto', border: '1px solid #E4E7EE', padding: '0px 30px 0px 30px' }}>
        {(pageType === PAGE_TYPES.PHONE) ? this.renderPhoneField() : this.renderOTPField()
        }
      </div>
    );
  };
}

StudentLogin.propTypes = {
  validateUID: PropTypes.func.isRequired,
  hasTimerExpired: PropTypes.bool,
  singleSignOn: PropTypes.func.isRequired,
  updateTimerExpirey: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth,
  hasTimerExpired: auth.hasTimerExpired,
});

export default connect(mapStateToProps, { validateUID, updateTimerExpirey, singleSignOn })(StudentLogin);
