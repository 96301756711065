import { UPDATE_LOADER_STATE } from '../actions';

const INITIAL_STATE = {
  loaderConfig: {
    show: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOADER_STATE:
      return { ...state, loaderConfig: { ...state.loaderConfig, show: action.payload } };
    default:
      return state;
  }
};
