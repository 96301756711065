import { UPDATE_AUTH, SHOW_TOAST, HIDE_TOAST } from '../actions';
import { TOAST_MESSAGE_TYPES, ERROR_TYPE, USER_TYPE } from '../shared/constants/fieldTypes';

const INITIAL_STATE = {
  user: {},
  mode: null,
  userType: USER_TYPE.INSTITUTE,
  userTypes: [],
  multiUserAuthId: null,
  loader: true,
  hasTimerExpired: false,
  showToast: false,
  toastMessage: '',
  toastMessageType: TOAST_MESSAGE_TYPES.SUCCESS,
  errorType: ERROR_TYPE.NO_ERROR,
  blockStudentlogin: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, ...action.payload, loader: false };
    case SHOW_TOAST:
      return { ...state, showToast: true, toastMessage: action.message, toastMessageType: action.messageType };
    case HIDE_TOAST:
      return { ...state, showToast: false, toastMessage: '', toastMessageType: TOAST_MESSAGE_TYPES.SUCCESS };
    default:
      return state;
  }
};
