import React from 'react';
import { connect } from 'react-redux';
import { forgotPassword, showToast } from '../actions';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { ERROR_MESSAGES, RESET_PASSWORD, AUTH_MODE, ICON_CONFIG } from '../shared/constants/fieldTypes';
import constants from '../shared/constants';
import { isMobileDevice } from '../helpers/Utils';
import { sendEvent } from '../helpers/Analytics';


const IS_MOBILE = isMobileDevice();
const { primaryButtonColor, primaryBackgroundColor } = constants();

const styles = {
  passwordInputStyle : {
    maxWidth: 450,
    minWidth: IS_MOBILE ? window.screen.width - 140 : 410,
    height: 40,
    paddingLeft: 10,
    margin: "10px 0px",
    backgroundColor: primaryBackgroundColor,
  },
  boxStyle : {
    width: IS_MOBILE ? window.screen.width - 100 : 450,
    backgroundColor: primaryButtonColor,
    justifyContent: 'center',
    paddingTop: 11,
    paddingBottom: 11,
    cursor: 'pointer',
    borderRadius: 2,
    boxShadow: '0px 3px 6px 0px rgba(108,92,231,0.5)',
    marginBottom: 5,
    textAlign: 'center',
    marginTop: 20,
  },
  textStyle : {
    color: '#FFFFFF',
    fontSize: 14,
    marginBottom: 0,
    textAlign: 'center',
    cursor: 'pointer',
  },
  pointerStyle: {
    textAlign: 'center',
    color: primaryButtonColor,
    cursor: 'pointer',
    marginTop: 20,
  },
};
const urlParams = new URLSearchParams(window.location.search);
const resetPasswordToken = urlParams.get(RESET_PASSWORD.RESET_PASSWORD_TOKEN);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      tooltipContent: '',
      showPassword: false,
    };
    this.tooltipShow = this.tooltipShow.bind(this);
    this.checkResetPasswordConditions= this.checkResetPasswordConditions.bind(this); 
  }

  componentDidMount(){
    sendEvent('SetPasswordInput', 'ResetPassword', 'View')
  }

  tooltipShow(tooltipContent, tooltipShowType, tooltip, additionalStateProps = {}) {
    this.setState({ tooltipContent, tooltipShowType, ...additionalStateProps }, () => {
      ReactTooltip.show(tooltip);
    });
  }

  checkResetPasswordConditions(e) {
    const { password, passwordConfirmation } = this.state;
      e.preventDefault();
      if (password.length < 8) {
        this.tooltipShow(ERROR_MESSAGES.PASSWORD_MIN_LENGTH, 'password', this.passwordTooltip)
      } else if (passwordConfirmation !== password) {
        this.tooltipShow(ERROR_MESSAGES.PASSWORD_MISMATCH, 'password-confirm', this.passwordConfirmationTooltip)
      } else if (!resetPasswordToken) {
        this.tooltipShow(ERROR_MESSAGES.INVALID_PASSWORD_RESET_TOKEN, 'password', this.passwordTooltip)
      } else {
        this.props.forgotPassword({ password, reset_password_token: resetPasswordToken, type: AUTH_MODE.RESET_PASSWORD }).then((response) => {
          if (!response.success) {
            this.tooltipShow(response.message, 'password', this.passwordTooltip)
          } else {
            sendEvent('ResetPasswordSuccessful', 'ResetPassword', 'Log')
          }
        });
      }
  }

  render() {
    const { password, passwordConfirmation, showPassword } = this.state;
    return (
      <div style={{ width: 'fit-content', backgroundColor: '#FFFFFF', borderRadius: 4, boxShadow: "0px 3px 6px #343B401A", padding: 40, margin: 'auto' }}>
        <div style={{ fontSize: 28, fontWeight: 'bold', textAlign: 'left', marginBottom: 20 }}>Reset Password</div>
        <div style={{ fontSize: 14, textAlign: 'left', marginBottom: 6 }}>Password</div>
        <div ref={ref => this.passwordTooltip = ref} data-tip='tooltip' data-for="password-tooltip">
          <input id="reset-password-email" type={ (showPassword) ? "text" : "Password" } maxLength="40" style={styles.passwordInputStyle}
            onChange={(event) => {
              this.setState({ password: event.target.value })
              ReactTooltip.hide(this.passwordTooltip)
            }}
            onFocus={()=>{
                sendEvent('PasswordInput', 'ResetPassword', 'Click')}
            }
            onKeyPress={(event) => event.key === 'Enter' &&  this.checkResetPasswordConditions(event) }
            value={password}
          />
          </div>
          <ReactTooltip id="password-tooltip" place="left" type="error" effect="solid"
          getContent={() => { if (this.state.tooltipShowType === 'password') { return this.state.tooltipContent } return null;}}
          afterHide={() => { this.setState({ tooltipContent: null }); }} />
        <div style={{ fontSize: 14, textAlign: 'left', marginBottom: 6 }}>Confirm Password</div>
        <div ref={ref => this.passwordConfirmationTooltip = ref} data-tip='tooltip' data-for="password-confirm-tooltip">
        <input id="reset-password-confirmation-email" type={ (showPassword) ? "text" : "Password" } maxLength="40" style={styles.passwordInputStyle}
          onChange={(event) => {
            this.setState({ passwordConfirmation: event.target.value })
            ReactTooltip.hide(this.passwordConfirmationTooltip)
          }}
          onFocus={()=>{
            sendEvent('ConfirmPasswordInput', 'ResetPassword', 'Click')}
          }
          onKeyPress={(event) => event.key === 'Enter' &&  this.checkResetPasswordConditions(event) }
          value={passwordConfirmation}
        />
        <img style={{ cursor:'pointer' }} src={ (showPassword) ? ICON_CONFIG.SHOW_PASSWORD : ICON_CONFIG.HIDE_PASSWORD } onClick={ ()=>{ this.setState({ showPassword : !showPassword })}} width={35} height={35} alt="success"/>
        </div>
        <ReactTooltip place="left" type="error" effect="solid" id="password-confirm-tooltip"
        getContent={() => { if (this.state.tooltipShowType === 'password-confirm') { return this.state.tooltipContent } return null; }}
        afterHide={() => { this.setState({ tooltipContent: null }); }} />
        <div style={styles.boxStyle} onClick={ (event) =>{ 
          sendEvent('ConfirmButton', 'ResetPassword', 'Click')
          this.checkResetPasswordConditions(event) }}>
          <span style={styles.textStyle}>Reset Password</span>
        </div>
      </div>
    );
  };
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  errorType: PropTypes.number,
  toastMessage: PropTypes.string,
  toastMessageType: PropTypes.string,
}

const mapStateToProps = ({ auth }) => ({
  auth,
  errorType: auth.errorType,
  toastMessage: auth.toastMessage,
  showToast: auth.showToast,
  toastMessageType: auth.toastMessageType,
});

export default connect(mapStateToProps, { forgotPassword, showToast })(ResetPassword);
