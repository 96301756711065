import Cookies from 'universal-cookie';
import Api from '../helpers/Api';
import constants from '../shared/constants';
import { encrypt } from '../helpers/CryptoHelper';
import { TOAST_MESSAGE_TYPES, USER_TYPE, AUTH_MODE } from '../shared/constants/fieldTypes';
import { sendEvent } from '../helpers/Analytics';
import { getInsituteDomain, getParentDomain } from '../helpers/Utils';
import { isMobileDevice } from '../helpers/Utils';
import { createBrowserHistory } from 'history';

const IS_MOBILE = isMobileDevice();
const history = createBrowserHistory({
    forceRefresh: true,
  });
const cookies = new Cookies();
const Constants = constants();

export const MEMBERSHIP_TYPES = {
  ADMIN: "Admin",
  TEACHER: "Teacher",
  GUARDIAN: "Guardian",
  COUNSELLOR: "Counsellor",
  STUDENT: "Student",
}

export const UPDATE_AUTHENTICATION_MODE = 'Auth/UPDATE_AUTHENTICATION_MODE';
export const UPDATE_USER_TYPE = 'Auth/UPDATE_USER_TYPE';
export const UPDATE_USER_TYPES = 'Auth/UPDATE_USER_TYPES';
export const UPDATE_AUTH = 'Auth/UPDATE_AUTH';
export const SHOW_TOAST = 'Auth/SHOW_TOAST_MESSAGE';
export const HIDE_TOAST = 'Auth/HIDE_TOAST_MESSAGE';
const deviceType = IS_MOBILE ? 'Mobile' : 'Desktop'
export const singleSignOn = (payloadData, password = false) => async (dispatch, getState) => {
  let utmDetails = cookies.get('utmDetails');
  let { userType, multiUserAuthId } = getState().auth
  userType = (userType==='STUDENT') ? 'Student' : (userType==='INSTITUTE') ? 'Institute' : 'Parent'
  let payload = { ...payloadData };
  if (utmDetails === undefined) {
    utmDetails = { "deviceType" : deviceType}
  } else {
    utmDetails.deviceType = deviceType;
  }
  if (payloadData.send_otp) {
    payload["send_otp"] = payloadData.send_otp;
  }
  if (payloadData.user_type === USER_TYPE.INSTITUTE) {
    payload = payloadData;
    payload["utm_details"] = utmDetails
    if (payloadData.otp !== undefined) {
      payload["otp"] = payloadData.otp;
    }
  } else if (payloadData.user_type === USER_TYPE.STUDENT || payloadData.user_type === USER_TYPE.PARENT) {
    payload["phone_number"] = payloadData.phone_number;
    payload["auth_type"] = payloadData.auth_type;
    payload["user_type"] = payloadData.user_type; 
    payload["utm_details"] = utmDetails
    if (password) {
      payload["password"] = payloadData.password;
    } else {
      if (payloadData.otp !== "") {
        payload["otp"] = payloadData.otp;
      }
    }
  }
  if (multiUserAuthId) {
    payload["membership_id"] = multiUserAuthId;
  }
  const response = await Api({
    method: 'post',
    url: '/authentications/authenticate',
    data: { secret: encrypt(payload) },
  });
  if (response.success) {
    if (response.isLoggedIn) {
      dispatch({ type: UPDATE_AUTH, payload: { user: response.user }});
      await cookies.set('token', response.token, { domain: Constants.cookie.domainRegex, secure: Constants.cookie.secure });
      await cookies.set('user', response.user, { domain: Constants.cookie.domainRegex, secure: Constants.cookie.secure });
      await cookies.set('roles', response.roles, { domain: Constants.cookie.domainRegex, secure: Constants.cookie.secure });
      dispatch({ type: UPDATE_AUTH, payload: { user: response.user }});
      const forwardingUrl = decodeURIComponent(new URLSearchParams(window.location.search).get("continue"));
      if (response.institute_sign_up) {
        return response;
      } else if (response.classboard_sign_in){
        if (response.institute_sign_in) {
          window.location.replace(getInsituteDomain(response.classboard_sign_in, response.user.type === MEMBERSHIP_TYPES.TEACHER));
        } else {
          window.location.replace(Constants.landingUrl + '/virtual_meeting');
        }
      } else if (response.institute_sign_in) {
        window.location.replace(getInsituteDomain());
      } else if (response.parent_sign_in){
        window.location.replace(getParentDomain());
      } else if (!response.user.name || response.user.name === '') {
        window.location.replace(Constants.landingUrl);
      } else if (forwardingUrl && forwardingUrl !== "null") {
        window.location.replace(forwardingUrl);
      } else {
        window.location.replace(Constants.landingUrl);
      }
    } else {
      if (!response.success) {
        sendEvent('OTPSendError',`${userType}SignIn`, 'Log' ,{ phone: payloadData.phone });
      } else {
        sendEvent('SmsOTPReceived',`${userType}SignIn`, 'Log', { phone: payloadData.phone});
      }
    }
  }
  dispatch(showToast(response.message, response.success ? TOAST_MESSAGE_TYPES.SUCCESS : TOAST_MESSAGE_TYPES.ERROR ));
  return response;
}

export const validateUID = (payload) => async (dispatch) => {
  return await Api({
    method: 'post',
    url: '/authentications/validate',
    data: { secret: encrypt({ uid_data: payload.uidData, uid_type: payload.uidType, device_type: IS_MOBILE ? 'Mobile' : 'Desktop', send_otp: payload.sendOtp, from_student_login: !!payload.fromStudentLogin}) },
  });
}

export const forgotPassword = (payload) => async (dispatch) => {
  const response = await Api({
    method: 'post',
    url: '/authentications/reset_password',
    data: { secret: encrypt(payload) },
  });
  if (response.success && payload.type === AUTH_MODE.RESET_PASSWORD) {
    history.push("/sign_in")
  }
  return response;
}

export const logout = () => async (dispatch) => {
  Object.keys(cookies.getAll()).forEach(cookieName => {
    cookies.remove(cookieName, { secure: Constants.cookie.secure, domain: Constants.cookie.domainRegex });
    }
  );
}

export const updateAuth = (payload) => {
  return {
    type: UPDATE_AUTH,
    payload,
  }
}

export const updateTimerExpirey = (hasTimerExpired) => async (dispatch) => {
  dispatch({
    type: UPDATE_AUTH,
    payload: { hasTimerExpired }
  });
}

export const showToast = (message, messageType=TOAST_MESSAGE_TYPES.SUCCESS, duration = 10000) => async(dispatch, getState) => {
  dispatch({ type: SHOW_TOAST, message, messageType });
  setTimeout(() => {
    dispatch({ type: HIDE_TOAST });
  }, duration)
}

export const closeToastMessage = () => async(dispatch) => {
  dispatch({ type: HIDE_TOAST });
} 