import Api from '../helpers/Api';
import { convertConvention, getInsituteDomain, getParentDomain } from '../helpers/Utils';
import { encrypt } from '../helpers/CryptoHelper';
import { updateAuth } from '../actions';
import { USER_TYPE } from '../shared/constants/fieldTypes';
import constants from '../shared/constants';
const Constants = constants();

export const UPDATE_USER_TYPES = 'Auth/UPDATE_USER_TYPES';

export const getUserTypes = () => async ( dispatch ) => {
    const response = await Api({
        method: 'get',
        url: '/authentications/get_user_types'
      });
      if(response.success) {
        let userTypes = [];
        let currentUserType = '';
        if (response.block_student_login) {
          dispatch(updateAuth({ blockStudentlogin: response.block_student_login}))
        }
        response.user_types.map((userType) =>{
          const type = convertConvention(userType.user_type)
          userType.user_type = type
          if(userType.id === userType.current_membership_id){
            currentUserType = type;
          }
          return userTypes.push(userType)
        })
        const payload = { userTypes, userType: currentUserType, multiUserAuthId: userTypes[0].current_membership_id}
        dispatch(updateAuth(payload))
      }
}

export const updateCurrentMembership = (payload) => async ( dispatch, getState) => {
  const { userType } = getState().auth
    const response = await Api({
        method: 'post',
        url: '/authentications/update_membership',
        data: { secret: encrypt(payload) },
      });
      if(response.success) {
        if( userType === USER_TYPE.INSTITUTE ){
          window.location.replace(getInsituteDomain());
        }else if ( userType === USER_TYPE.STUDENT) {
          window.location.replace(Constants.landingUrl);
        } else {
          window.location.replace(getParentDomain());
        }
        return response.user_types
      }
}
