import axios from 'axios';
// import { showToast } from '../actions/index';
import constants from '../shared/constants';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const Constants = constants();

const history = createBrowserHistory({
  forceRefresh: true,
});

const getToken = () => {
  const token = cookies.get('token');
  if (token) {
    return `bearer ${cookies.get('token')}`;
  }
  return '';
}

export const getUser = () => {
  if (typeof global.user !== 'undefined' && global.user && global.user !== '') {
    return global.user;
  }
  const user = cookies.get('user');
  return user || {};
}

const Api = (options) => {
  const onSuccess = (response) => {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Successful!', 'response');
    }
    return response.data;
  };

  const onError = (error) => {

    if (error.response && error.response.status === 503) {
      history.push('/notify_server_maintenance');
      return;
    }

    if (process.env.REACT_APP_ENV !== 'production') {
      console.log('Request Failed:', error);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.log('Status:', error.response.status);
        console.log('Data:', error.response.data);
        console.log('Headers:', error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.log('Error Message:', error.message);
      }
    }
    return error.response || error.message;
  };

  if (options.method === "post") {
    return axios({
      method: options.method,
      url: `${Constants.api.url}${options.url}`,
      data: options.data,
      transformRequest: [function (data) {
          data = data || {};
          data["token"] = getToken()
          data["accountsVersionCode"] = Constants.accountsVersionCode
          return queryString.stringify(data);
        }],
      })
    .then(onSuccess)
    .catch(onError);
  } else {
    let url = `${Constants.api.url}${options.url}`
    if (options.url.includes("?")) {
      url += `&token=${getToken()}&accountsVersionCode=${Constants.accountsVersionCode}`
    } else {
      url += `?token=${getToken()}&accountsVersionCode=${Constants.accountsVersionCode}`
    }
    return axios({
      method: options.method,
      url,
    })
    .then(onSuccess)
    .catch(onError);
  }
};

export default Api;
