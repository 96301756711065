module.exports = {
  api: {
    url: `https://api.${process.env.REACT_APP_DOMAIN}`,
    responseType: 'json',
    timeout: 40000,
    withCredentials: true,
  },
  cookie: {
    secure: true,
    subDomain: `accounts.${process.env.REACT_APP_DOMAIN}`,
    domainRegex: `.${process.env.REACT_APP_DOMAIN}`,
    instituteDomain: `qbank.${process.env.REACT_APP_DOMAIN}`,
    parentDomain: `parent.${process.env.REACT_APP_DOMAIN}`,
  },
  landingUrl: `https://www.${process.env.REACT_APP_DOMAIN}`,
  qbankOnboardingUrl: `https://qbank.${process.env.REACT_APP_DOMAIN}`,
  accountsVersionCode: "1.0.0",
  contact: "080-471-87111",
  email: "support@ideaboard.xyz",
  company_url: "www.ideaboard.xyz",
  primaryBackgroundColor: "#F5F8FA",
  primaryButtonColor: "#6C5CE7",
  playStoreUrl: "https://play.google.com/store/apps/details?id=com.ideaboard&referrer",
  defaultErrorMessage: "Sorry something went wrong, try again later"
}
