import React from 'react';
import { connect } from 'react-redux';
import { forgotPassword, showToast } from '../actions';
import PropTypes from 'prop-types';
import { AUTH_MODE, ERROR_MESSAGES } from '../shared/constants/fieldTypes';
import { validateEmail } from '../helpers/Utils';
import ReactTooltip from 'react-tooltip';
import { ActivityIndicator } from './common';
import { sendEvent } from '../helpers/Analytics';


import constants from '../shared/constants';

const { primaryButtonColor } = constants();

const styles = {
    inputStyle : {
      width: 360,
      height: 40,
      paddingLeft: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    pStyle: {
      textAlign: 'center',
      color: primaryButtonColor,
      cursor: 'pointer',
    },
    boxStyle : {
      width: 360,
      backgroundColor: primaryButtonColor,
      justifyContent: 'center',
      paddingTop: 11,
      paddingBottom: 11,
      cursor: 'pointer',
      borderRadius: 2,
      boxShadow: '0px 3px 6px 0px rgba(108,92,231,0.5)',
      margin: 'auto',
      textAlign: 'center',
      marginTop: 10,

    },
    textStyle : {
      color: '#FFFFFF',
      fontSize: 14,
      marginBottom: 0,
      textAlign: 'center'
    },
    pointerStyle: {
      textAlign: 'center',
      color: primaryButtonColor,
      cursor: 'pointer',
      marginTop: 20,
    },
  };


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      tooltipContent: '',
      isLoader: false,
      tooltipType: 'error',
      showGetLink: true,
      getLinkResponse: { success: false, message: '' }
    };
    this.tooltipShow = this.tooltipShow.bind(this);
  }
  componentDidMount() {
    sendEvent('EmailInput', 'ResetPassword', 'View')
  }

  tooltipShow(tooltipContent, tooltipShowType, tooltip, additionalStateProps = {}) {
    this.setState({ tooltipContent, tooltipShowType, ...additionalStateProps }, () => {
      ReactTooltip.show(tooltip);
    });
  }

  getResetPasswordLink (e) {
    const { email } = this.state;
    this.setState({ isLoader : true })
      e.preventDefault();
      if (!validateEmail(email)) {
        this.tooltipShow(ERROR_MESSAGES.INVALID_EMAIL, 'email', this.emailTooltip)
        this.setState({ isLoader : false })
      } else {
        this.props.forgotPassword({ email, type: AUTH_MODE.FORGOT_PASSWORD }).then((response) => {
            this.setState({ getLinkResponse: { success: response.success, message: response.message } , showGetLink: false })
            if(response.success) {
              sendEvent('ResetPasswordLinkSent', 'ResetPassword', 'Log') 
            }
            this.setState({ isLoader: false })
        });
      }
  }

  render() {
    const { email, tooltipType, showGetLink, getLinkResponse } = this.state;
    return (
      <div style={{ width: 'fit-content', backgroundColor: '#FFFFFF', borderRadius: 4, padding: 40, margin: 'auto', boxShadow: "0px 3px 6px #343B401A" }}>
        <div style={{ fontSize: 14, textAlign: 'left', marginBottom: 6 }}>Email</div>
        <div>
          <div ref={ref => this.emailTooltip = ref} data-tip='tooltip'>
            <input id="reset-password-email" type="text" maxLength="40" style={styles.inputStyle}
              placeholder="eg. name@gmail.com"
              onFocus ={()=>{
                sendEvent('EmailInput', 'ResetPassword', 'Click')
              }}
              onChange={(event) => {
                ReactTooltip.hide(this.emailTooltip)
                this.setState({ email: event.target.value })}
              }
              value={email}
              onKeyPress={(event) => event.key === 'Enter' &&  this.getResetPasswordLink(event) }
            />
          </div>
          <ReactTooltip place="left" type={tooltipType} effect="solid" getContent={() => { return this.state.tooltipContent }} afterHide={() => { this.setState({ tooltipContent: null }); }} />
        </div>
        { showGetLink 
            ? <div style={styles.boxStyle} onClick={ (event)=>{ 
                sendEvent('GetResetPasswordSuccesful', 'ResetPassword', 'Log')
                this.getResetPasswordLink(event) 
              }}>
                <span style={styles.textStyle}>{ this.state.isLoader ? <ActivityIndicator /> : 'Get reset password link'}</span>
              </div> 
            : <div style={{ color: getLinkResponse.success ? 'green' : 'red', textAlign: 'center', paddingTop: 11 }}> 
                {getLinkResponse.message || <div>Please try <a href='/forgot_password'>again</a></div> }
              </div> 
        }
      </div>
    );
  };
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  errorType: PropTypes.number,
  toastMessage: PropTypes.string,
  toastMessageType: PropTypes.string,
}

const mapStateToProps = ({ auth }) => ({
  auth,
  errorType: auth.errorType,
  toastMessage: auth.toastMessage,
  showToast: auth.showToast,
  toastMessageType: auth.toastMessageType,
});

export default connect(mapStateToProps, { forgotPassword, showToast })(ForgotPassword);
